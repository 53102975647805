import { Active } from "../../models";
import { TabView, TabPanel } from 'primereact/tabview';
import { Button } from 'primereact/button';
import { Divider } from 'primereact/divider';
import { InputText } from "primereact/inputtext";
import { useState } from 'react';

import user from "../../images/user.png";

const Profile = ({ active, relad }: Props) => {
    const [isEditingNickname, setIsEditingNickname] = useState(false);
    const [newNickname, setNewNickname] = useState(active?.activeUser?.User?.nick || '');

    const handleSaveNickname = () => {
        console.log('Saving new nickname:', newNickname);
        setIsEditingNickname(false);
    };

    return (
        <div className="card bg-white p-6">
            <div className="flex items-center gap-6 mb-8 p-4 bg-gray-50 rounded-xl">
                <div className="relative">
                    <img 
                        src={user} 
                        alt={active?.activeUser?.User?.nick || "Profile"} 
                        className="w-24 h-24 rounded-full border-4 border-white shadow-md" 
                    />
                    <div className="absolute -bottom-2 -right-2">
                        <Button 
                            icon="pi pi-camera" 
                            className="p-button-rounded p-button-text" 
                            tooltip="Change photo"
                        />
                    </div>
                </div>
                <div>
                    <div className="flex items-center gap-2">
                        {isEditingNickname ? (
                            <div className="flex items-center gap-2">
                                <InputText 
                                    value={newNickname}
                                    onChange={(e) => setNewNickname(e.target.value)}
                                    className="text-xl font-semibold p-1 h-8"
                                    autoFocus
                                />
                                <Button 
                                    icon="pi pi-check" 
                                    className="p-button-rounded p-button-text text-green-500 flex items-center justify-center w-8 h-8 min-w-[2rem]" 
                                    onClick={handleSaveNickname}
                                    tooltip="Save"
                                />
                                <Button 
                                    icon="pi pi-times" 
                                    className="p-button-rounded p-button-text text-red-500 flex items-center justify-center w-8 h-8 min-w-[2rem]" 
                                    onClick={() => setIsEditingNickname(false)}
                                    tooltip="Cancel"
                                />
                            </div>
                        ) : (
                            <>
                                <h2 className="text-2xl font-bold text-gray-800 mb-1">
                                    {active?.activeUser?.User?.nick}
                                </h2>
                                <Button 
                                    icon="pi pi-pencil" 
                                    className="p-button-rounded p-button-text text-gray-500 flex items-center justify-center w-8 h-8 min-w-[2rem]" 
                                    tooltip="Edit nickname"
                                    tooltipOptions={{ position: 'top' }}
                                    onClick={() => setIsEditingNickname(true)}
                                />
                            </>
                        )}
                    </div>
                    <p className="text-gray-500 flex items-center gap-2">
                        <i className="pi pi-envelope"></i>
                        {active?.activeUser?.User?.email}
                    </p>
                </div>
            </div>

            <TabView 
                className="profile-tabs"
            >
                <TabPanel 
                    header="Personal Information" 
                    leftIcon="pi pi-user mr-2" 
                    className="bg-white shadow-sm rounded-lg p-6"
                    pt={{
                        headerAction: ({ selected }: { selected: boolean }) => ({
                            className: `
                                p-3 px-4 rounded-t-lg transition-colors duration-200 bg-gray-50
                                ${selected 
                                    ? 'text-blue-700 border-b-2 border-blue-500 font-medium bg-blue-50' 
                                    : 'text-gray-600 hover:text-gray-800'
                                }
                            `
                        })
                    }}
                >
                    <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
                        <div className="space-y-6">
                            <div className="profile-field">
                                <h3 className="text-sm font-medium text-gray-600 mb-2">Full Name</h3>
                                <p className="p-4 bg-gray-50 rounded-lg text-gray-800 shadow-sm">
                                    {active?.activeUser?.Record?.fullname}
                                </p>
                            </div>
                            <div className="profile-field">
                                <h3 className="text-sm text-gray-500 mb-1">Email Address</h3>
                                <p className="p-3 bg-gray-50 rounded-lg text-gray-800 flex items-center gap-2">
                                    <i className="pi pi-envelope text-gray-400"></i>
                                    {active?.activeUser?.User?.email}
                                </p>
                            </div>
                            <div className="profile-field">
                                <h3 className="text-sm text-gray-500 mb-1">Phone Numbers</h3>
                                <div className="space-y-2">
                                    <p className="p-3 bg-gray-50 rounded-lg text-gray-800 flex items-center gap-2">
                                        <i className="pi pi-phone text-gray-400"></i>
                                        {active?.activeUser?.Record?.cell_phone}
                                    </p>
                                    <p className="p-3 bg-gray-50 rounded-lg text-gray-800 flex items-center gap-2">
                                        <i className="pi pi-home text-gray-400"></i>
                                        {active?.activeUser?.Record?.home_phone}
                                    </p>
                                </div>
                            </div>
                        </div>

                        <div className="space-y-4">
                            <div className="profile-field">
                                <h3 className="text-sm text-gray-500 mb-1">Address</h3>
                                <p className="p-3 bg-gray-50 rounded-lg text-gray-800">
                                    {active?.activeUser?.Record?.address}
                                </p>
                                <div className="grid grid-cols-2 gap-2 mt-2">
                                    <p className="p-3 bg-gray-50 rounded-lg text-gray-800">
                                        {active?.activeUser?.Record?.city}
                                    </p>
                                    <p className="p-3 bg-gray-50 rounded-lg text-gray-800">
                                        {active?.activeUser?.Record?.state}
                                    </p>
                                </div>
                                <p className="p-3 bg-gray-50 rounded-lg text-gray-800 mt-2">
                                    {active?.activeUser?.Record?.zip_code}
                                </p>
                            </div>
                        </div>
                    </div>

                    <Divider className="my-6" />

                    <div className="position-info">
                        <h2 className="text-xl font-bold text-gray-800 mb-4">Position Information</h2>
                        <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                            <div className="space-y-4">
                                <div className="profile-field">
                                    <h3 className="text-sm text-gray-500 mb-1">Position Applied</h3>
                                    <p className="p-3 bg-gray-50 rounded-lg text-gray-800">
                                        {active?.activeUser?.Record?.position_applied}
                                    </p>
                                </div>
                                <div className="profile-field">
                                    <h3 className="text-sm text-gray-500 mb-1">Available Start Date</h3>
                                    <p className="p-3 bg-gray-50 rounded-lg text-gray-800">
                                        {active?.activeUser?.Record?.available_start_date}
                                    </p>
                                </div>
                            </div>
                            <div className="space-y-4">
                                <div className="profile-field">
                                    <h3 className="text-sm text-gray-500 mb-1">Applying As</h3>
                                    <p className="p-3 bg-gray-50 rounded-lg text-gray-800">
                                        {active?.activeUser?.Record?.applying_as}
                                    </p>
                                </div>
                                <div className="profile-field">
                                    <h3 className="text-sm text-gray-500 mb-1">Available For</h3>
                                    <p className="p-3 bg-gray-50 rounded-lg text-gray-800">
                                        {active?.activeUser?.Record?.available_for}
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="mt-6 flex justify-end">
                        <Button 
                            label="Update Profile" 
                            icon="pi pi-user-edit"
                            className="p-button-primary text-lg px-3 py-3 rounded-md"
                            style={{ minWidth: '200px' }}
                        />
                    </div>
                </TabPanel>

                <TabPanel 
                    header="Security" 
                    leftIcon="pi pi-lock mr-2" 
                    className="bg-white shadow-sm rounded-lg p-6"
                    pt={{
                        headerAction: ({ selected }: { selected: boolean }) => ({
                            className: `
                                p-3 px-4 rounded-t-lg transition-colors duration-200 bg-gray-50
                                ${selected 
                                    ? 'text-blue-700 border-b-2 border-blue-500 font-medium bg-blue-50' 
                                    : 'text-gray-600 hover:text-gray-800'
                                }
                            `
                        })
                    }}
                >
                    <div className="max-w-md mx-auto space-y-6">
                        <form className="space-y-6">
                            <div className="profile-field">
                                <h3 className="text-sm font-medium text-gray-600 mb-2">Current Password</h3>
                                <InputText 
                                    type="password" 
                                    className="w-full p-4 rounded-lg border border-gray-200 focus:border-blue-500 focus:ring-2 focus:ring-blue-200 transition-all" 
                                    placeholder="Enter current password" 
                                />
                            </div>
                            <div className="profile-field">
                                <h3 className="text-sm text-gray-500 mb-1">New Password</h3>
                                <InputText 
                                    type="password" 
                                    className="w-full p-4 rounded-lg border border-gray-200 focus:border-blue-500 focus:ring-2 focus:ring-blue-200 transition-all" 
                                    placeholder="Enter new password" 
                                />
                            </div>
                            <div className="profile-field">
                                <h3 className="text-sm text-gray-500 mb-1">Confirm Password</h3>
                                <InputText 
                                    type="password" 
                                    className="w-full p-4 rounded-lg border border-gray-200 focus:border-blue-500 focus:ring-2 focus:ring-blue-200 transition-all" 
                                    placeholder="Confirm new password" 
                                />
                            </div>
                            <div className="flex justify-end">
                                <Button 
                                    label="Change Password" 
                                    icon="pi pi-lock"
                                    className="p-button-primary text-lg px-3 py-3 rounded-md"
                                    style={{ minWidth: '200px' }} 
                                />
                            </div>
                        </form>
                    </div>
                </TabPanel>
            </TabView>
        </div>
    )
}

type Props = {
    active?: Active;
    relad(): void;
};

export { Profile };