import React, { useState, useEffect } from 'react';
import { FilterMatchMode, FilterOperator } from 'primereact/api';
import { DataTable, DataTableExpandedRows, DataTableRowEvent, DataTableValueArray, DataTableFilterMeta, DataTableValue } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { Tag } from 'primereact/tag';
// import { Toast } from 'primereact/toast';

import { useCoreRequestsNewClients } from "../../hooks";
import { RequetsNewClient, NewClient } from '../../../commons'
import { Affix } from 'antd';
import { DataRequestNewClients } from "./components"
// import { RequestNewClient } from "../../../data-types";
import { Active } from "../../../../models";

const defaultFilters: DataTableFilterMeta = {
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
    "last_name": {
        operator: FilterOperator.AND,
        constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
    },
    "first_name": {
        operator: FilterOperator.AND,
        constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
    },
};

const QARequestsClients = ({ active, relad }: Props) => {
    const { requestsNewClients, isLoading, reloadRequestsNewClients } = useCoreRequestsNewClients();

    const [filters, setFilters] = useState<DataTableFilterMeta>(defaultFilters);
    const [requestActive, setRequestActive] = useState<DataTableValue | undefined>(undefined);
    const [showWindowsNewClient, setShowWindowsNewClient] = useState<boolean>(false);
    // const [loading, setLoading] = useState<boolean>(false);
    const [show, setShow] = useState<boolean>(false);
    const [globalFilterValue, setGlobalFilterValue] = useState<string>('');
    const [expandedRows, setExpandedRows] = useState<DataTableExpandedRows | DataTableValueArray | undefined>(undefined);

    const onClickRequest = (event: DataTableRowEvent) => {
        setRequestActive(event.data);
        setShowWindowsNewClient(true);
    };
    const onRowExpand = (event: DataTableRowEvent) => {
        const rowData = event.data;
        let _expandedRows = {};

        requestsNewClients?.request?.forEach((p) => { if (p.ID === rowData.ID) { (_expandedRows[p.ID] = true) } });
        // console.log(_expandedRows);
        setExpandedRows(_expandedRows);

    };

    const onRowCollapse = (event: DataTableRowEvent) => {
        const rowKey = event.data.ID.toString();
        setExpandedRows(prevExpandedRows => ({ ...prevExpandedRows, [rowKey]: false }));
    };

    const expandAll = () => {
        let _expandedRows: DataTableExpandedRows = {};

        requestsNewClients?.request?.forEach((p) => (_expandedRows[`${p.ID}`] = true));

        setExpandedRows(_expandedRows);
    };

    const collapseAll = () => {
        setExpandedRows(undefined);
    };

    const allowExpansion = (rowData) => {
        return rowData.dob !== "";
    };

    const rowExpansionTemplate = (data) => {
        return (
            <DataRequestNewClients relad={relad} data={data} />
        );
    };

    const onGlobalFilterChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value;
        let _filters = { ...filters };

        // @ts-ignore
        _filters['global'].value = value;

        setFilters(_filters);
        setGlobalFilterValue(value);
    };

    const handleClick = () => {
        setShow(true);
    };

    const SplitDate = (rowData) => {
        // return <GetInfo email={rowData.email} date="fullname" />
        return rowData.CreatedAt.split("T")[0];
    };

    const Services = (rowData) => {
        // return <GetInfo email={rowData.email} date="fullname" />
        return (
            <div>
                {rowData.case_management && <Tag severity="warning" value="Case Management"></Tag>}
                {rowData.individual_therapy && <Tag severity="success" value="Individual Therapy" className='ml-1'></Tag>}
                {rowData.family_therapy && <Tag severity="danger" value="Family Therapy" className='ml-1'></Tag>}
                {rowData.adult_psr && <Tag severity="info" value="Adult PSR" className='ml-1'></Tag>}
                {rowData.psychiatrist && <Tag style={{ background: 'linear-gradient(-225deg,#AC32E4 0%,#7918F2 48%,#4801FF 100%)' }} value="Psychiatrist" className='ml-1'></Tag>}
                {rowData.other && <Tag value="Other" className='ml-1'></Tag>}

            </div>
        );
    };


    const renderHeader = () => {
        return (
            <Affix offsetTop={44}>
                <div className="flex justify-between items-center w-full" style={{ backgroundColor: "#F8F9FA" }}>
                    <div className="flex w-1/4 items-center">
                        <span className="p-input-icon-left w-full">
                            <i className="pi pi-search ml-2" />
                            <InputText value={globalFilterValue} onChange={onGlobalFilterChange} placeholder="Search" className='border-gray-400 p-3 pl-8' />
                        </span>
                    </div>
                    <div className="flex justify-end">
                        <Button icon="pi pi-plus" label="Expand All" onClick={expandAll} text className="mr-2" />
                        <Button icon="pi pi-minus" label="Collapse All" onClick={collapseAll} text className="mr-2" />
                        <Button
                            icon="pi pi-user-plus"
                            label="New Client Request"
                            onClick={handleClick}
                            text
                            autoFocus />
                    </div>
                </div>
            </Affix>
        );
    };

    const header = renderHeader();

    const closed = () => {
        setShow(false);
        setShowWindowsNewClient(false);
    }

    useEffect(() => {
        initFilters();
    }, []);

    useEffect(() => {
        reloadRequestsNewClients();
    }, [relad]);

    const initFilters = () => {
        setFilters(defaultFilters);
        setGlobalFilterValue('');
    };

    return (
        <div className="card w-full mt-10">
            {show === true && <RequetsNewClient relad={relad} show={show} closed={closed} />}
            {showWindowsNewClient === true && <NewClient relad={relad} active={active} show={showWindowsNewClient} closed={closed} data={requestActive} />}
            <DataTable
                value={requestsNewClients?.request}
                onRowClick={onClickRequest}
                expandedRows={expandedRows}
                onRowToggle={(e) => setExpandedRows(e.data)}
                onRowExpand={onRowExpand}
                onRowCollapse={onRowCollapse}
                rowExpansionTemplate={rowExpansionTemplate}
                paginator
                showGridlines
                rows={10}
                loading={isLoading}
                dataKey="ID"
                filters={filters}
                // filterDisplay="row"
                globalFilterFields={['last_name', 'first_name']}
                header={header}
                emptyMessage="No customers found."
            >
                <Column expander={allowExpansion} style={{ width: '5rem' }} />
                <Column field="last_name" filter header="Last Name" style={{ minWidth: '8rem' }} sortable />
                <Column field="first_name" filter header="First Name" style={{ minWidth: '8rem' }} sortable />
                <Column field="sexo" header="SEXO" sortable />
                <Column field="dob" header="DOB" sortable />
                <Column field="dob" header="Requested Services" body={Services} style={{ maxWidth: '12rem' }} />
                <Column field="CreatedAt" header="Application Date" body={SplitDate} sortable />
            </DataTable>
        </div>
    );
}
type Props = {
    active?: Active;
    relad(): void;
};
export { QARequestsClients }