import { useState} from 'react';
 const initializeGoal1 = (spInitialAddendums) => {
    return {
        goal1a1: spInitialAddendums.goal1.goal1a1 ?? false,
        goal1a1Type: spInitialAddendums.goal1.goal1a1Type ?? "",
        goal1a1Long: spInitialAddendums.goal1.goal1a1Long ?? "",
        goal1a1Responsible: spInitialAddendums.goal1.goal1a1Responsible ?? "",
        goal1a1StartDate: spInitialAddendums.goal1.goal1a1StartDate ?? "",
        goal1a1TargetDate: spInitialAddendums.goal1.goal1a1TargetDate ?? "",
        goal1a1Met: spInitialAddendums.goal1.goal1a1Met ?? false,
        goal1a1MetDate: spInitialAddendums.goal1.goal1a1MetDate ?? "",
        goal1a1OnGoing: spInitialAddendums.goal1.goal1a1OnGoing ?? false,
        goal1a1NotMet: spInitialAddendums.goal1.goal1a1NotMet ?? false,
        // --
        goal1a2: spInitialAddendums.goal1.goal1a2 ?? false,
        goal1a2Type: spInitialAddendums.goal1.goal1a2Type ?? "",
        goal1a2Long: spInitialAddendums.goal1.goal1a2Long ?? "",
        goal1a2Responsible: spInitialAddendums.goal1.goal1a2Responsible ?? "",
        goal1a2StartDate: spInitialAddendums.goal1.goal1a2StartDate ?? "",
        goal1a2TargetDate: spInitialAddendums.goal1.goal1a2TargetDate ?? "",
        goal1a2Met: spInitialAddendums.goal1.goal1a2Met ?? false,
        goal1a2MetDate: spInitialAddendums.goal1.goal1a2MetDate ?? "",
        goal1a2OnGoing: spInitialAddendums.goal1.goal1a2OnGoing ?? false,
        goal1a2NotMet: spInitialAddendums.goal1.goal1a2NotMet ?? false,
        // --
        goal1b: spInitialAddendums.goal1.goal1b ?? false,
        goal1bType: spInitialAddendums.goal1.goal1bType ?? "",
        goal1bLong: spInitialAddendums.goal1.goal1bLong ?? "",
        goal1bResponsible: spInitialAddendums.goal1.goal1bResponsible ?? "",
        goal1bStartDate: spInitialAddendums.goal1.goal1bStartDate ?? "",
        goal1bTargetDate: spInitialAddendums.goal1.goal1bTargetDate ?? "",
        goal1bMet: spInitialAddendums.goal1.goal1bMet ?? false,
        goal1bMetDate: spInitialAddendums.goal1.goal1bMetDate ?? "",
        goal1bOnGoing: spInitialAddendums.goal1.goal1bOnGoing ?? false,
        goal1bNotMet: spInitialAddendums.goal1.goal1bNotMet ?? false,
        // --
        goal1c: spInitialAddendums.goal1.goal1c ?? false,
        goal1cType: spInitialAddendums.goal1.goal1cType ?? "",
        goal1cLong: spInitialAddendums.goal1.goal1cLong ?? "",
        goal1cResponsible: spInitialAddendums.goal1.goal1cResponsible ?? "",
        goal1cStartDate: spInitialAddendums.goal1.goal1cStartDate ?? "",
        goal1cTargetDate: spInitialAddendums.goal1.goal1cTargetDate ?? "",
        goal1cMet: spInitialAddendums.goal1.goal1cMet ?? false,
        goal1cMetDate: spInitialAddendums.goal1.goal1cMetDate ?? "",
        goal1cOnGoing: spInitialAddendums.goal1.goal1cOnGoing ?? false,
        goal1cNotMet: spInitialAddendums.goal1.goal1cNotMet ?? false,
        // --
        goal1d: spInitialAddendums.goal1.goal1d ?? false,
        goal1dType: spInitialAddendums.goal1.goal1dType ?? "",
        goal1dLong: spInitialAddendums.goal1.goal1dLong ?? "",
        goal1dResponsible: spInitialAddendums.goal1.goal1dResponsible ?? "",
        goal1dStartDate: spInitialAddendums.goal1.goal1dStartDate ?? "",
        goal1dTargetDate: spInitialAddendums.goal1.goal1dTargetDate ?? "",
        goal1dMet: spInitialAddendums.goal1.goal1dMet ?? false,
        goal1dMetDate: spInitialAddendums.goal1.goal1dMetDate ?? "",
        goal1dOnGoing: spInitialAddendums.goal1.goal1dOnGoing ?? false,
        goal1dNotMet: spInitialAddendums.goal1.goal1dNotMet ?? false,
        // --
        goal1e: spInitialAddendums.goal1.goal1e ?? false,
        goal1eType: spInitialAddendums.goal1.goal1eType ?? "",
        goal1eLong: spInitialAddendums.goal1.goal1eLong ?? "",
        goal1eResponsible: spInitialAddendums.goal1.goal1eResponsible ?? "",
        goal1eStartDate: spInitialAddendums.goal1.goal1eStartDate ?? "",
        goal1eTargetDate: spInitialAddendums.goal1.goal1eTargetDate ?? "",
        goal1eMet: spInitialAddendums.goal1.goal1eMet ?? false,
        goal1eMetDate: spInitialAddendums.goal1.goal1eMetDate ?? "",
        goal1eOnGoing: spInitialAddendums.goal1.goal1eOnGoing ?? false,
        goal1eNotMet: spInitialAddendums.goal1.goal1eNotMet ?? false,
        // --
        goal1f1: spInitialAddendums.goal1.goal1f1 ?? false,
        goal1f1Type: spInitialAddendums.goal1.goal1f1Type ?? "",
        goal1f1Long: spInitialAddendums.goal1.goal1f1Long ?? "",
        goal1f1Responsible: spInitialAddendums.goal1.goal1f1Responsible ?? "",
        goal1f1StartDate: spInitialAddendums.goal1.goal1f1StartDate ?? "",
        goal1f1TargetDate: spInitialAddendums.goal1.goal1f1TargetDate ?? "",
        goal1f1Met: spInitialAddendums.goal1.goal1f1Met ?? false,
        goal1f1MetDate: spInitialAddendums.goal1.goal1f1MetDate ?? "",
        goal1f1OnGoing: spInitialAddendums.goal1.goal1f1OnGoing ?? false,
        goal1f1NotMet: spInitialAddendums.goal1.goal1f1NotMet ?? false,
        // --
        goal1f2: spInitialAddendums.goal1.goal1f2 ?? false,
        goal1f2Type: spInitialAddendums.goal1.goal1f2Type ?? "",
        goal1f2Text: spInitialAddendums.goal1.goal1f2Text ?? "",
        goal1f2Long: spInitialAddendums.goal1.goal1f2Long ?? "",
        goal1f2Responsible: spInitialAddendums.goal1.goal1f2Responsible ?? "",
        goal1f2StartDate: spInitialAddendums.goal1.goal1f2StartDate ?? "",
        goal1f2TargetDate: spInitialAddendums.goal1.goal1f2TargetDate ?? "",
        goal1f2Met: spInitialAddendums.goal1.goal1f2Met ?? false,
        goal1f2MetDate: spInitialAddendums.goal1.goal1f2MetDate ?? "",
        goal1f2OnGoing: spInitialAddendums.goal1.goal1f2OnGoing ?? false,
        goal1f2NotMet: spInitialAddendums.goal1.goal1f2NotMet ?? false,
    };
};
 const initializeGoal2 = (spInitialAddendums) => {
    return {
        goal2a1: spInitialAddendums.goal2.goal2a1 ?? false,
        goal2a1Type: spInitialAddendums.goal2.goal2a1Type ?? "",
        goal2a1Long: spInitialAddendums.goal2.goal2a1Long ?? "",
        goal2a1Responsible: spInitialAddendums.goal2.goal2a1Responsible ?? "",
        goal2a1StartDate: spInitialAddendums.goal2.goal2a1StartDate ?? "",
        goal2a1TargetDate: spInitialAddendums.goal2.goal2a1TargetDate ?? "",
        goal2a1Met: spInitialAddendums.goal2.goal2a1Met ?? false,
        goal2a1MetDate: spInitialAddendums.goal2.goal2a1MetDate ?? "",
        goal2a1OnGoing: spInitialAddendums.goal2.goal2a1OnGoing ?? false,
        goal2a1NotMet: spInitialAddendums.goal2.goal2a1NotMet ?? false,
        // --
        goal2b: spInitialAddendums.goal2.goal2b ?? false,
        goal2bType: spInitialAddendums.goal2.goal2bType ?? "",
        goal2bLong: spInitialAddendums.goal2.goal2bLong ?? "",
        goal2bResponsible: spInitialAddendums.goal2.goal2bResponsible ?? "",
        goal2bStartDate: spInitialAddendums.goal2.goal2bStartDate ?? "",
        goal2bTargetDate: spInitialAddendums.goal2.goal2bTargetDate ?? "",
        goal2bMet: spInitialAddendums.goal2.goal2bMet ?? false,
        goal2bMetDate: spInitialAddendums.goal2.goal2bMetDate ?? "",
        goal2bOnGoing: spInitialAddendums.goal2.goal2bOnGoing ?? false,
        goal2bNotMet: spInitialAddendums.goal2.goal2bNotMet ?? false,
        // --
        goal2c: spInitialAddendums.goal2.goal2c ?? false,
        goal2cType: spInitialAddendums.goal2.goal2cType ?? "",
        goal2cLong: spInitialAddendums.goal2.goal2cLong ?? "",
        goal2cResponsible: spInitialAddendums.goal2.goal2cResponsible ?? "",
        goal2cStartDate: spInitialAddendums.goal2.goal2cStartDate ?? "",
        goal2cTargetDate: spInitialAddendums.goal2.goal2cTargetDate ?? "",
        goal2cMet: spInitialAddendums.goal2.goal2cMet ?? false,
        goal2cMetDate: spInitialAddendums.goal2.goal2cMetDate ?? "",
        goal2cOnGoing: spInitialAddendums.goal2.goal2cOnGoing ?? false,
        goal2cNotMet: spInitialAddendums.goal2.goal2cNotMet ?? false,
        // --
        goal2d1: spInitialAddendums.goal2.goal2d1 ?? false,
        goal2d1Type: spInitialAddendums.goal2.goal2d1Type ?? "",
        goal2d1Long: spInitialAddendums.goal2.goal2d1Long ?? "",
        goal2d1Responsible: spInitialAddendums.goal2.goal2d1Responsible ?? "",
        goal2d1StartDate: spInitialAddendums.goal2.goal2d1StartDate ?? "",
        goal2d1TargetDate: spInitialAddendums.goal2.goal2d1TargetDate ?? "",
        goal2d1Met: spInitialAddendums.goal2.goal2d1Met ?? false,
        goal2d1MetDate: spInitialAddendums.goal2.goal2d1MetDate ?? "",
        goal2d1OnGoing: spInitialAddendums.goal2.goal2d1OnGoing ?? false,
        goal2d1NotMet: spInitialAddendums.goal2.goal2d1NotMet ?? false,
        // --
        goal2d2: spInitialAddendums.goal2.goal2d2 ?? false,
        goal2d2Type: spInitialAddendums.goal2.goal2d2Type ?? "",
        goal2d2Text: spInitialAddendums.goal2.goal2d2Text ?? "",
        goal2d2Long: spInitialAddendums.goal2.goal2d2Long ?? "",
        goal2d2Responsible: spInitialAddendums.goal2.goal2d2Responsible ?? "",
        goal2d2StartDate: spInitialAddendums.goal2.goal2d2StartDate ?? "",
        goal2d2TargetDate: spInitialAddendums.goal2.goal2d2TargetDate ?? "",
        goal2d2Met: spInitialAddendums.goal2.goal2d2Met ?? false,
        goal2d2MetDate: spInitialAddendums.goal2.goal2d2MetDate ?? "",
        goal2d2OnGoing: spInitialAddendums.goal2.goal2d2OnGoing ?? false,
        goal2d2NotMet: spInitialAddendums.goal2.goal2d2NotMet ?? false,
        // --
        goal2d3: spInitialAddendums.goal2.goal2d3 ?? false,
        goal2d3Type: spInitialAddendums.goal2.goal2d3Type ?? "",
        goal2d3Text: spInitialAddendums.goal2.goal2d3Text ?? "",
        goal2d3Long: spInitialAddendums.goal2.goal2d3Long ?? "",
        goal2d3Responsible: spInitialAddendums.goal2.goal2d3Responsible ?? "",
        goal2d3StartDate: spInitialAddendums.goal2.goal2d3StartDate ?? "",
        goal2d3TargetDate: spInitialAddendums.goal2.goal2d3TargetDate ?? "",
        goal2d3Met: spInitialAddendums.goal2.goal2d3Met ?? false,
        goal2d3MetDate: spInitialAddendums.goal2.goal2d3MetDate ?? "",
        goal2d3OnGoing: spInitialAddendums.goal2.goal2d3OnGoing ?? false,
        goal2d3NotMet: spInitialAddendums.goal2.goal2d3NotMet ?? false,
        // --
        goal2d4: spInitialAddendums.goal2.goal2d4 ?? false,
        goal2d4Type: spInitialAddendums.goal2.goal2d4Type ?? "",
        goal2d4Text: spInitialAddendums.goal2.goal2d4Text ?? "",
        goal2d4Long: spInitialAddendums.goal2.goal2d4Long ?? "",
        goal2d4Responsible: spInitialAddendums.goal2.goal2d4Responsible ?? "",
        goal2d4StartDate: spInitialAddendums.goal2.goal2d4StartDate ?? "",
        goal2d4TargetDate: spInitialAddendums.goal2.goal2d4TargetDate ?? "",
        goal2d4Met: spInitialAddendums.goal2.goal2d4Met ?? false,
        goal2d4MetDate: spInitialAddendums.goal2.goal2d4MetDate ?? "",
        goal2d4OnGoing: spInitialAddendums.goal2.goal2d4OnGoing ?? false,
        goal2d4NotMet: spInitialAddendums.goal2.goal2d4NotMet ?? false,
        // --
        goal2d5: spInitialAddendums.goal2.goal2d5 ?? false,
        goal2d5Type: spInitialAddendums.goal2.goal2d5Type ?? "",
        goal2d5Text: spInitialAddendums.goal2.goal2d5Text ?? "",
        goal2d5Long: spInitialAddendums.goal2.goal2d5Long ?? "",
        goal2d5Responsible: spInitialAddendums.goal2.goal2d5Responsible ?? "",
        goal2d5StartDate: spInitialAddendums.goal2.goal2d5StartDate ?? "",
        goal2d5TargetDate: spInitialAddendums.goal2.goal2d5TargetDate ?? "",
        goal2d5Met: spInitialAddendums.goal2.goal2d5Met ?? false,
        goal2d5MetDate: spInitialAddendums.goal2.goal2d5MetDate ?? "",
        goal2d5OnGoing: spInitialAddendums.goal2.goal2d5OnGoing ?? false,
        goal2d5NotMet: spInitialAddendums.goal2.goal2d5NotMet ?? false,
        // --
        goal2d6: spInitialAddendums.goal2.goal2d6 ?? false,
        goal2d6Type: spInitialAddendums.goal2.goal2d6Type ?? "",
        goal2d6Text: spInitialAddendums.goal2.goal2d6Text ?? "",
        goal2d6Long: spInitialAddendums.goal2.goal2d6Long ?? "",
        goal2d6Responsible: spInitialAddendums.goal2.goal2d6Responsible ?? "",
        goal2d6StartDate: spInitialAddendums.goal2.goal2d6StartDate ?? "",
        goal2d6TargetDate: spInitialAddendums.goal2.goal2d6TargetDate ?? "",
        goal2d6Met: spInitialAddendums.goal2.goal2d6Met ?? false,
        goal2d6MetDate: spInitialAddendums.goal2.goal2d6MetDate ?? "",
        goal2d6OnGoing: spInitialAddendums.goal2.goal2d6OnGoing ?? false,
        goal2d6NotMet: spInitialAddendums.goal2.goal2d6NotMet ?? false,
        // --
        goal2d7: spInitialAddendums.goal2.goal2d7 ?? false,
        goal2d7Type: spInitialAddendums.goal2.goal2d7Type ?? "",
        goal2d7Text: spInitialAddendums.goal2.goal2d7Text ?? "",
        goal2d7Long: spInitialAddendums.goal2.goal2d7Long ?? "",
        goal2d7Responsible: spInitialAddendums.goal2.goal2d7Responsible ?? "",
        goal2d7StartDate: spInitialAddendums.goal2.goal2d7StartDate ?? "",
        goal2d7TargetDate: spInitialAddendums.goal2.goal2d7TargetDate ?? "",
        goal2d7Met: spInitialAddendums.goal2.goal2d7Met ?? false,
        goal2d7MetDate: spInitialAddendums.goal2.goal2d7MetDate ?? "",
        goal2d7OnGoing: spInitialAddendums.goal2.goal2d7OnGoing ?? false,
        goal2d7NotMet: spInitialAddendums.goal2.goal2d7NotMet ?? false,
        // --
        goal2d8: spInitialAddendums.goal2.goal2d8 ?? false,
        goal2d8Type: spInitialAddendums.goal2.goal2d8Type ?? "",
        goal2d8Text: spInitialAddendums.goal2.goal2d8Text ?? "",
        goal2d8Long: spInitialAddendums.goal2.goal2d8Long ?? "",
        goal2d8Responsible: spInitialAddendums.goal2.goal2d8Responsible ?? "",
        goal2d8StartDate: spInitialAddendums.goal2.goal2d8StartDate ?? "",
        goal2d8TargetDate: spInitialAddendums.goal2.goal2d8TargetDate ?? "",
        goal2d8Met: spInitialAddendums.goal2.goal2d8Met ?? false,
        goal2d8MetDate: spInitialAddendums.goal2.goal2d8MetDate ?? "",
        goal2d8OnGoing: spInitialAddendums.goal2.goal2d8OnGoing ?? false,
        goal2d8NotMet: spInitialAddendums.goal2.goal2d8NotMet ?? false,
        // --
        goal2d9: spInitialAddendums.goal2.goal2d9 ?? false,
        goal2d9Type: spInitialAddendums.goal2.goal2d9Type ?? "",
        goal2d9Text: spInitialAddendums.goal2.goal2d9Text ?? "",
        goal2d9Long: spInitialAddendums.goal2.goal2d9Long ?? "",
        goal2d9Responsible: spInitialAddendums.goal2.goal2d9Responsible ?? "",
        goal2d9StartDate: spInitialAddendums.goal2.goal2d9StartDate ?? "",
        goal2d9TargetDate: spInitialAddendums.goal2.goal2d9TargetDate ?? "",
        goal2d9Met: spInitialAddendums.goal2.goal2d9Met ?? false,
        goal2d9MetDate: spInitialAddendums.goal2.goal2d9MetDate ?? "",
        goal2d9OnGoing: spInitialAddendums.goal2.goal2d9OnGoing ?? false,
        goal2d9NotMet: spInitialAddendums.goal2.goal2d9NotMet ?? false,
        // --
        goal2d10: spInitialAddendums.goal2.goal2d10 ?? false,
        goal2d10Type: spInitialAddendums.goal2.goal2d10Type ?? "",
        goal2d10Text: spInitialAddendums.goal2.goal2d10Text ?? "",
        goal2d10Long: spInitialAddendums.goal2.goal2d10Long ?? "",
        goal2d10Responsible: spInitialAddendums.goal2.goal2d10Responsible ?? "",
        goal2d10StartDate: spInitialAddendums.goal2.goal2d10StartDate ?? "",
        goal2d10TargetDate: spInitialAddendums.goal2.goal2d10TargetDate ?? "",
        goal2d10Met: spInitialAddendums.goal2.goal2d10Met ?? false,
        goal2d10MetDate: spInitialAddendums.goal2.goal2d10MetDate ?? "",
        goal2d10OnGoing: spInitialAddendums.goal2.goal2d10OnGoing ?? false,
        goal2d10NotMet: spInitialAddendums.goal2.goal2d10NotMet ?? false,
        // --
        goal2d11: spInitialAddendums.goal2.goal2d11 ?? false,
        goal2d11Type: spInitialAddendums.goal2.goal2d11Type ?? "",
        goal2d11Text: spInitialAddendums.goal2.goal2d11Text ?? "",
        goal2d11Long: spInitialAddendums.goal2.goal2d11Long ?? "",
        goal2d11Responsible: spInitialAddendums.goal2.goal2d11Responsible ?? "",
        goal2d11StartDate: spInitialAddendums.goal2.goal2d11StartDate ?? "",
        goal2d11TargetDate: spInitialAddendums.goal2.goal2d11TargetDate ?? "",
        goal2d11Met: spInitialAddendums.goal2.goal2d11Met ?? false,
        goal2d11MetDate: spInitialAddendums.goal2.goal2d11MetDate ?? "",
        goal2d11OnGoing: spInitialAddendums.goal2.goal2d11OnGoing ?? false,
        goal2d11NotMet: spInitialAddendums.goal2.goal2d11NotMet ?? false,
        // --
        goal2d12: spInitialAddendums.goal2.goal2d12 ?? false,
        goal2d12Type: spInitialAddendums.goal2.goal2d12Type ?? "",
        goal2d12Text: spInitialAddendums.goal2.goal2d12Text ?? "",
        goal2d12Long: spInitialAddendums.goal2.goal2d12Long ?? "",
        goal2d12Responsible: spInitialAddendums.goal2.goal2d12Responsible ?? "",
        goal2d12StartDate: spInitialAddendums.goal2.goal2d12StartDate ?? "",
        goal2d12TargetDate: spInitialAddendums.goal2.goal2d12TargetDate ?? "",
        goal2d12Met: spInitialAddendums.goal2.goal2d12Met ?? false,
        goal2d12MetDate: spInitialAddendums.goal2.goal2d12MetDate ?? "",
        goal2d12OnGoing: spInitialAddendums.goal2.goal2d1dOnGoing ?? false,
        goal2d12NotMet: spInitialAddendums.goal2.goal2d12NotMet ?? false,
        // --
        goal2d13: spInitialAddendums.goal2.goal2d13 ?? false,
        goal2d13Type: spInitialAddendums.goal2.goal2d13Type ?? "",
        goal2d13Text: spInitialAddendums.goal2.goal2d13Text ?? "",
        goal2d13Long: spInitialAddendums.goal2.goal2d13Long ?? "",
        goal2d13Responsible: spInitialAddendums.goal2.goal2d13Responsible ?? "",
        goal2d13StartDate: spInitialAddendums.goal2.goal2d13StartDate ?? "",
        goal2d13TargetDate: spInitialAddendums.goal2.goal2d13TargetDate ?? "",
        goal2d13Met: spInitialAddendums.goal2.goal2d13Met ?? false,
        goal2d13MetDate: spInitialAddendums.goal2.goal2d13MetDate ?? "",
        goal2d13OnGoing: spInitialAddendums.goal2.goal2d13OnGoing ?? false,
        goal2d13NotMet: spInitialAddendums.goal2.goal2d13NotMet ?? false,
        // --
        goal2e1: spInitialAddendums.goal2.goal2e1 ?? false,
        goal2e1Type: spInitialAddendums.goal2.goal2e1Type ?? "",
        goal2e1Long: spInitialAddendums.goal2.goal2e1Long ?? "",
        goal2e1Responsible: spInitialAddendums.goal2.goal2e1Responsible ?? "",
        goal2e1StartDate: spInitialAddendums.goal2.goal2e1StartDate ?? "",
        goal2e1TargetDate: spInitialAddendums.goal2.goal2e1TargetDate ?? "",
        goal2e1Met: spInitialAddendums.goal2.goal2e1Met ?? false,
        goal2e1MetDate: spInitialAddendums.goal2.goal2e1MetDate ?? "",
        goal2e1OnGoing: spInitialAddendums.goal2.goal2e1OnGoing ?? false,
        goal2e1NotMet: spInitialAddendums.goal2.goal2e1NotMet ?? false,
        // --
        goal2e2: spInitialAddendums.goal2.goal2e2 ?? false,
        goal2e2Type: spInitialAddendums.goal2.goal2e2Type ?? "",
        goal2e2Long: spInitialAddendums.goal2.goal2e2Long ?? "",
        goal2e2Responsible: spInitialAddendums.goal2.goal2e2Responsible ?? "",
        goal2e2StartDate: spInitialAddendums.goal2.goal2e2StartDate ?? "",
        goal2e2TargetDate: spInitialAddendums.goal2.goal2e2TargetDate ?? "",
        goal2e2Met: spInitialAddendums.goal2.goal2e2Met ?? false,
        goal2e2MetDate: spInitialAddendums.goal2.goal2e2MetDate ?? "",
        goal2e2OnGoing: spInitialAddendums.goal2.goal2e2OnGoing ?? false,
        goal2e2NotMet: spInitialAddendums.goal2.goal2e2NotMet ?? false,
        // --
        goal2e3: spInitialAddendums.goal2.goal2e3 ?? false,
        goal2e3Type: spInitialAddendums.goal2.goal2e3Type ?? "",
        goal2e3Long: spInitialAddendums.goal2.goal2e3Long ?? "",
        goal2e3Responsible: spInitialAddendums.goal2.goal2e3Responsible ?? "",
        goal2e3StartDate: spInitialAddendums.goal2.goal2e3StartDate ?? "",
        goal2e3TargetDate: spInitialAddendums.goal2.goal2e3TargetDate ?? "",
        goal2e3Met: spInitialAddendums.goal2.goal2e3Met ?? false,
        goal2e3MetDate: spInitialAddendums.goal2.goal2e3MetDate ?? "",
        goal2e3OnGoing: spInitialAddendums.goal2.goal2e3OnGoing ?? false,
        goal2e3NotMet: spInitialAddendums.goal2.goal2e3NotMet ?? false,
        // --
        goal2e4: spInitialAddendums.goal2.goal2e4 ?? false,
        goal2e4Type: spInitialAddendums.goal2.goal2e4Type ?? "",
        goal2e4Long: spInitialAddendums.goal2.goal2e4Long ?? "",
        goal2e4Responsible: spInitialAddendums.goal2.goal2e4Responsible ?? "",
        goal2e4StartDate: spInitialAddendums.goal2.goal2e4StartDate ?? "",
        goal2e4TargetDate: spInitialAddendums.goal2.goal2e4TargetDate ?? "",
        goal2e4Met: spInitialAddendums.goal2.goal2e4Met ?? false,
        goal2e4MetDate: spInitialAddendums.goal2.goal2e4MetDate ?? "",
        goal2e4OnGoing: spInitialAddendums.goal2.goal2e4OnGoing ?? false,
        goal2e4NotMet: spInitialAddendums.goal2.goal2e4NotMet ?? false,
        // --
        goal2e5: spInitialAddendums.goal2.goal2e5 ?? false,
        goal2e5Type: spInitialAddendums.goal2.goal2e5Type ?? "",
        goal2e5Text: spInitialAddendums.goal2.goal2e5Text ?? "",
        goal2e5Long: spInitialAddendums.goal2.goal2e5Long ?? "",
        goal2e5Responsible: spInitialAddendums.goal2.goal2e5Responsible ?? "",
        goal2e5StartDate: spInitialAddendums.goal2.goal2e5StartDate ?? "",
        goal2e5TargetDate: spInitialAddendums.goal2.goal2e5TargetDate ?? "",
        goal2e5Met: spInitialAddendums.goal2.goal2e5Met ?? false,
        goal2e5MetDate: spInitialAddendums.goal2.goal2e5MetDate ?? "",
        goal2e5OnGoing: spInitialAddendums.goal2.goal2e5OnGoing ?? false,
        goal2e5NotMet: spInitialAddendums.goal2.goal2e5NotMet ?? false,
    };
};
 const initializeGoal3 = (spInitialAddendums) => {
    return {
        goal3a: spInitialAddendums.goal3.goal3a ?? false,
        goal3aType: spInitialAddendums.goal3.goal3aType ?? "",
        goal3aLong: spInitialAddendums.goal3.goal3aLong ?? "",
        goal3aResponsible: spInitialAddendums.goal3.goal3aResponsible ?? "",
        goal3aStartDate: spInitialAddendums.goal3.goal3aStartDate ?? "",
        goal3aTargetDate: spInitialAddendums.goal3.goal3aTargetDate ?? "",
        goal3aMet: spInitialAddendums.goal3.goal3aMet ?? false,
        goal3aMetDate: spInitialAddendums.goal3.goal3aMetDate ?? "",
        goal3aOnGoing: spInitialAddendums.goal3.goal3aOnGoing ?? false,
        goal3aNotMet: spInitialAddendums.goal3.goal3aNotMet ?? false,
        // --
        goal3a1: spInitialAddendums.goal3.goal3a1 ?? false,
        goal3a1Type: spInitialAddendums.goal3.goal3a1Type ?? "",
        goal3a1Long: spInitialAddendums.goal3.goal3a1Long ?? "",
        goal3a1Responsible: spInitialAddendums.goal3.goal3a1Responsible ?? "",
        goal3a1StartDate: spInitialAddendums.goal3.goal3a1StartDate ?? "",
        goal3a1TargetDate: spInitialAddendums.goal3.goal3a1TargetDate ?? "",
        goal3a1Met: spInitialAddendums.goal3.goal3a1Met ?? false,
        goal3a1MetDate: spInitialAddendums.goal3.goal3a1MetDate ?? "",
        goal3a1OnGoing: spInitialAddendums.goal3.goal3a1OnGoing ?? false,
        goal3a1NotMet: spInitialAddendums.goal3.goal3a1NotMet ?? false,
        // --
        goal3a2: spInitialAddendums.goal3.goal3a2 ?? false,
        goal3a2Type: spInitialAddendums.goal3.goal3a2Type ?? "",
        goal3a2Long: spInitialAddendums.goal3.goal3a2Long ?? "",
        goal3a2Responsible: spInitialAddendums.goal3.goal3a2Responsible ?? "",
        goal3a2StartDate: spInitialAddendums.goal3.goal3a2StartDate ?? "",
        goal3a2TargetDate: spInitialAddendums.goal3.goal3a2TargetDate ?? "",
        goal3a2Met: spInitialAddendums.goal3.goal3a2Met ?? false,
        goal3a2MetDate: spInitialAddendums.goal3.goal3a2MetDate ?? "",
        goal3a2OnGoing: spInitialAddendums.goal3.goal3a2OnGoing ?? false,
        goal3a2NotMet: spInitialAddendums.goal3.goal3a2NotMet ?? false,
        // --
        goal3a3: spInitialAddendums.goal3.goal3a3 ?? false,
        goal3a3Type: spInitialAddendums.goal3.goal3a3Type ?? "",
        goal3a3Long: spInitialAddendums.goal3.goal3a3Long ?? "",
        goal3a3Responsible: spInitialAddendums.goal3.goal3a3Responsible ?? "",
        goal3a3StartDate: spInitialAddendums.goal3.goal3a3StartDate ?? "",
        goal3a3TargetDate: spInitialAddendums.goal3.goal3a3TargetDate ?? "",
        goal3a3Met: spInitialAddendums.goal3.goal3a3Met ?? false,
        goal3a3MetDate: spInitialAddendums.goal3.goal3a3MetDate ?? "",
        goal3a3OnGoing: spInitialAddendums.goal3.goal3a3OnGoing ?? false,
        goal3a3NotMet: spInitialAddendums.goal3.goal3a3NotMet ?? false,
        // --
        goal3a4: spInitialAddendums.goal3.goal3a4 ?? false,
        goal3a4Type: spInitialAddendums.goal3.goal3a4Type ?? "",
        goal3a4Long: spInitialAddendums.goal3.goal3a4Long ?? "",
        goal3a4Responsible: spInitialAddendums.goal3.goal3a4Responsible ?? "",
        goal3a4StartDate: spInitialAddendums.goal3.goal3a4StartDate ?? "",
        goal3a4TargetDate: spInitialAddendums.goal3.goal3a4TargetDate ?? "",
        goal3a4Met: spInitialAddendums.goal3.goal3a4Met ?? false,
        goal3a4MetDate: spInitialAddendums.goal3.goal3a4MetDate ?? "",
        goal3a4OnGoing: spInitialAddendums.goal3.goal3a4OnGoing ?? false,
        goal3a4NotMet: spInitialAddendums.goal3.goal3a4NotMet ?? false,
        // --
        goal3a5: spInitialAddendums.goal3.goal3a5 ?? false,
        goal3a5Type: spInitialAddendums.goal3.goal3a5Type ?? "",
        goal3a5Long: spInitialAddendums.goal3.goal3a5Long ?? "",
        goal3a5Responsible: spInitialAddendums.goal3.goal3a5Responsible ?? "",
        goal3a5StartDate: spInitialAddendums.goal3.goal3a5StartDate ?? "",
        goal3a5TargetDate: spInitialAddendums.goal3.goal3a5TargetDate ?? "",
        goal3a5Met: spInitialAddendums.goal3.goal3a5Met ?? false,
        goal3a5MetDate: spInitialAddendums.goal3.goal3a5MetDate ?? "",
        goal3a5OnGoing: spInitialAddendums.goal3.goal3a5OnGoing ?? false,
        goal3a5NotMet: spInitialAddendums.goal3.goal3a5NotMet ?? false,
        // --
        goal3b1: spInitialAddendums.goal3.goal3b1 ?? false,
        goal3b1Type: spInitialAddendums.goal3.goal3b1Type ?? "",
        goal3b1Long: spInitialAddendums.goal3.goal3b1Long ?? "",
        goal3b1Responsible: spInitialAddendums.goal3.goal3b1Responsible ?? "",
        goal3b1StartDate: spInitialAddendums.goal3.goal3b1StartDate ?? "",
        goal3b1TargetDate: spInitialAddendums.goal3.goal3b1TargetDate ?? "",
        goal3b1Met: spInitialAddendums.goal3.goal3b1Met ?? false,
        goal3b1MetDate: spInitialAddendums.goal3.goal3b1MetDate ?? "",
        goal3b1OnGoing: spInitialAddendums.goal3.goal3b1OnGoing ?? false,
        goal3b1NotMet: spInitialAddendums.goal3.goal3b1NotMet ?? false,
        // --
        goal3b2: spInitialAddendums.goal3.goal3b2 ?? false,
        goal3b2Type: spInitialAddendums.goal3.goal3b2Type ?? "",
        goal3b2Long: spInitialAddendums.goal3.goal3b2Long ?? "",
        goal3b2Responsible: spInitialAddendums.goal3.goal3b2Responsible ?? "",
        goal3b2StartDate: spInitialAddendums.goal3.goal3b2StartDate ?? "",
        goal3b2TargetDate: spInitialAddendums.goal3.goal3b2TargetDate ?? "",
        goal3b2Met: spInitialAddendums.goal3.goal3b2Met ?? false,
        goal3b2MetDate: spInitialAddendums.goal3.goal3b2MetDate ?? "",
        goal3b2OnGoing: spInitialAddendums.goal3.goal3b2OnGoing ?? false,
        goal3b2NotMet: spInitialAddendums.goal3.goal3b2NotMet ?? false,
        // --
        goal3b3: spInitialAddendums.goal3.goal3b3 ?? false,
        goal3b3Type: spInitialAddendums.goal3.goal3b3Type ?? "",
        goal3b3Long: spInitialAddendums.goal3.goal3b3Long ?? "",
        goal3b3Responsible: spInitialAddendums.goal3.goal3b3Responsible ?? "",
        goal3b3StartDate: spInitialAddendums.goal3.goal3b3StartDate ?? "",
        goal3b3TargetDate: spInitialAddendums.goal3.goal3b3TargetDate ?? "",
        goal3b3Met: spInitialAddendums.goal3.goal3b3Met ?? false,
        goal3b3MetDate: spInitialAddendums.goal3.goal3b3MetDate ?? "",
        goal3b3OnGoing: spInitialAddendums.goal3.goal3b3OnGoing ?? false,
        goal3b3NotMet: spInitialAddendums.goal3.goal3b3NotMet ?? false,
        // --
        goal3b4: spInitialAddendums.goal3.goal3b4 ?? false,
        goal3b4Type: spInitialAddendums.goal3.goal3b4Type ?? "",
        goal3b4Long: spInitialAddendums.goal3.goal3b4Long ?? "",
        goal3b4Responsible: spInitialAddendums.goal3.goal3b4Responsible ?? "",
        goal3b4StartDate: spInitialAddendums.goal3.goal3b4StartDate ?? "",
        goal3b4TargetDate: spInitialAddendums.goal3.goal3b4TargetDate ?? "",
        goal3b4Met: spInitialAddendums.goal3.goal3b4Met ?? false,
        goal3b4MetDate: spInitialAddendums.goal3.goal3b4MetDate ?? "",
        goal3b4OnGoing: spInitialAddendums.goal3.goal3b4OnGoing ?? false,
        goal3b4NotMet: spInitialAddendums.goal3.goal3b4NotMet ?? false,
        // --
        goal3b5: spInitialAddendums.goal3.goal3b5 ?? false,
        goal3b5Type: spInitialAddendums.goal3.goal3b5Type ?? "",
        goal3b5Long: spInitialAddendums.goal3.goal3b5Long ?? "",
        goal3b5Responsible: spInitialAddendums.goal3.goal3b5Responsible ?? "",
        goal3b5StartDate: spInitialAddendums.goal3.goal3b5StartDate ?? "",
        goal3b5TargetDate: spInitialAddendums.goal3.goal3b5TargetDate ?? "",
        goal3b5Met: spInitialAddendums.goal3.goal3b5Met ?? false,
        goal3b5MetDate: spInitialAddendums.goal3.goal3b5MetDate ?? "",
        goal3b5OnGoing: spInitialAddendums.goal3.goal3b5OnGoing ?? false,
        goal3b5NotMet: spInitialAddendums.goal3.goal3b5NotMet ?? false,
        // --
        goal3b6: spInitialAddendums.goal3.goal3b6 ?? false,
        goal3b6Type: spInitialAddendums.goal3.goal3b6Type ?? "",
        goal3b6Long: spInitialAddendums.goal3.goal3b6Long ?? "",
        goal3b6Responsible: spInitialAddendums.goal3.goal3b6Responsible ?? "",
        goal3b6StartDate: spInitialAddendums.goal3.goal3b6StartDate ?? "",
        goal3b6TargetDate: spInitialAddendums.goal3.goal3b6TargetDate ?? "",
        goal3b6Met: spInitialAddendums.goal3.goal3b6Met ?? false,
        goal3b6MetDate: spInitialAddendums.goal3.goal3b6MetDate ?? "",
        goal3b6OnGoing: spInitialAddendums.goal3.goal3b6OnGoing ?? false,
        goal3b6NotMet: spInitialAddendums.goal3.goal3b6NotMet ?? false,
        // --
        goal3b7: spInitialAddendums.goal3.goal3b7 ?? false,
        goal3b7Type: spInitialAddendums.goal3.goal3b7Type ?? "",
        goal3b7Long: spInitialAddendums.goal3.goal3b7Long ?? "",
        goal3b7Responsible: spInitialAddendums.goal3.goal3b7Responsible ?? "",
        goal3b7StartDate: spInitialAddendums.goal3.goal3b7StartDate ?? "",
        goal3b7TargetDate: spInitialAddendums.goal3.goal3b7TargetDate ?? "",
        goal3b7Met: spInitialAddendums.goal3.goal3b7Met ?? false,
        goal3b7MetDate: spInitialAddendums.goal3.goal3b7MetDate ?? "",
        goal3b7OnGoing: spInitialAddendums.goal3.goal3b7OnGoing ?? false,
        goal3b7NotMet: spInitialAddendums.goal3.goal3b7NotMet ?? false,
        // --
        goal3b8: spInitialAddendums.goal3.goal3b8 ?? false,
        goal3b8Type: spInitialAddendums.goal3.goal3b8Type ?? "",
        goal3b8Long: spInitialAddendums.goal3.goal3b8Long ?? "",
        goal3b8Responsible: spInitialAddendums.goal3.goal3b8Responsible ?? "",
        goal3b8StartDate: spInitialAddendums.goal3.goal3b8StartDate ?? "",
        goal3b8TargetDate: spInitialAddendums.goal3.goal3b8TargetDate ?? "",
        goal3b8Met: spInitialAddendums.goal3.goal3b8Met ?? false,
        goal3b8MetDate: spInitialAddendums.goal3.goal3b8MetDate ?? "",
        goal3b8OnGoing: spInitialAddendums.goal3.goal3b8OnGoing ?? false,
        goal3b8NotMet: spInitialAddendums.goal3.goal3b8NotMet ?? false,
        // --
        // --
        goal3c1: spInitialAddendums.goal3.goal3c1 ?? false,
        goal3c1Type: spInitialAddendums.goal3.goal3c1Type ?? "",
        goal3c1Long: spInitialAddendums.goal3.goal3c1Long ?? "",
        goal3c1Responsible: spInitialAddendums.goal3.goal3c1Responsible ?? "",
        goal3c1StartDate: spInitialAddendums.goal3.goal3c1StartDate ?? "",
        goal3c1TargetDate: spInitialAddendums.goal3.goal3c1TargetDate ?? "",
        goal3c1Met: spInitialAddendums.goal3.goal3c1Met ?? false,
        goal3c1MetDate: spInitialAddendums.goal3.goal3c1MetDate ?? "",
        goal3c1OnGoing: spInitialAddendums.goal3.goal3c1OnGoing ?? false,
        goal3c1NotMet: spInitialAddendums.goal3.goal3c1NotMet ?? false,
        // --
        goal3c2: spInitialAddendums.goal3.goal3c2 ?? false,
        goal3c2Type: spInitialAddendums.goal3.goal3c2Type ?? "",
        goal3c2Long: spInitialAddendums.goal3.goal3c2Long ?? "",
        goal3c2Responsible: spInitialAddendums.goal3.goal3c2Responsible ?? "",
        goal3c2StartDate: spInitialAddendums.goal3.goal3c2StartDate ?? "",
        goal3c2TargetDate: spInitialAddendums.goal3.goal3c2TargetDate ?? "",
        goal3c2Met: spInitialAddendums.goal3.goal3c2Met ?? false,
        goal3c2MetDate: spInitialAddendums.goal3.goal3c2MetDate ?? "",
        goal3c2OnGoing: spInitialAddendums.goal3.goal3c2OnGoing ?? false,
        goal3c2NotMet: spInitialAddendums.goal3.goal3c2NotMet ?? false,
        // --
        goal3c3: spInitialAddendums.goal3.goal3c3 ?? false,
        goal3c3Type: spInitialAddendums.goal3.goal3c3Type ?? "",
        goal3c3Long: spInitialAddendums.goal3.goal3c3Long ?? "",
        goal3c3Responsible: spInitialAddendums.goal3.goal3c3Responsible ?? "",
        goal3c3StartDate: spInitialAddendums.goal3.goal3c3StartDate ?? "",
        goal3c3TargetDate: spInitialAddendums.goal3.goal3c3TargetDate ?? "",
        goal3c3Met: spInitialAddendums.goal3.goal3cMet ?? false,
        goal3c3MetDate: spInitialAddendums.goal3.goal3cMetDate ?? "",
        goal3c3OnGoing: spInitialAddendums.goal3.goal3cOnGoing ?? false,
        goal3c3NotMet: spInitialAddendums.goal3.goal3cNotMet ?? false,
        // --
        goal3c4: spInitialAddendums.goal3.goal3c4 ?? false,
        goal3c4Type: spInitialAddendums.goal3.goal3c4Type ?? "",
        goal3c4Long: spInitialAddendums.goal3.goal3c4Long ?? "",
        goal3c4Responsible: spInitialAddendums.goal3.goal3c4Responsible ?? "",
        goal3c4StartDate: spInitialAddendums.goal3.goal3c4StartDate ?? "",
        goal3c4TargetDate: spInitialAddendums.goal3.goal3c4TargetDate ?? "",
        goal3c4Met: spInitialAddendums.goal3.goal3c4Met ?? false,
        goal3c4MetDate: spInitialAddendums.goal3.goal3c4MetDate ?? "",
        goal3c4OnGoing: spInitialAddendums.goal3.goal3c4OnGoing ?? false,
        goal3c4NotMet: spInitialAddendums.goal3.goal3c4NotMet ?? false,
        // --
        goal3c5: spInitialAddendums.goal3.goal3c5 ?? false,
        goal3c5Type: spInitialAddendums.goal3.goal3c5Type ?? "",
        goal3c5Long: spInitialAddendums.goal3.goal3c5Long ?? "",
        goal3c5Responsible: spInitialAddendums.goal3.goal3c5Responsible ?? "",
        goal3c5StartDate: spInitialAddendums.goal3.goal3c5StartDate ?? "",
        goal3c5TargetDate: spInitialAddendums.goal3.goal3c5TargetDate ?? "",
        goal3c5Met: spInitialAddendums.goal3.goal3c5Met ?? false,
        goal3c5MetDate: spInitialAddendums.goal3.goal3c5MetDate ?? "",
        goal3c5OnGoing: spInitialAddendums.goal3.goal3c5OnGoing ?? false,
        goal3c5NotMet: spInitialAddendums.goal3.goal3c5NotMet ?? false,
        // --
        goal3c6: spInitialAddendums.goal3.goal3c6 ?? false,
        goal3c6Type: spInitialAddendums.goal3.goal3c6Type ?? "",
        goal3c6Long: spInitialAddendums.goal3.goal3c6Long ?? "",
        goal3c6Responsible: spInitialAddendums.goal3.goal3c6Responsible ?? "",
        goal3c6StartDate: spInitialAddendums.goal3.goal3c6StartDate ?? "",
        goal3c6TargetDate: spInitialAddendums.goal3.goal3c6TargetDate ?? "",
        goal3c6Met: spInitialAddendums.goal3.goal3c6Met ?? false,
        goal3c6MetDate: spInitialAddendums.goal3.goal3c6MetDate ?? "",
        goal3c6OnGoing: spInitialAddendums.goal3.goal3c6OnGoing ?? false,
        goal3c6NotMet: spInitialAddendums.goal3.goal3c6NotMet ?? false,
        // --
        goal3d1: spInitialAddendums.goal3.goal3d1 ?? false,
        goal3d1Type: spInitialAddendums.goal3.goal3d1Type ?? "",
        goal3d1Long: spInitialAddendums.goal3.goal3d1Long ?? "",
        goal3d1Responsible: spInitialAddendums.goal3.goal3d1Responsible ?? "",
        goal3d1StartDate: spInitialAddendums.goal3.goal3d1StartDate ?? "",
        goal3d1TargetDate: spInitialAddendums.goal3.goal3d1TargetDate ?? "",
        goal3d1Met: spInitialAddendums.goal3.goal3d1Met ?? false,
        goal3d1MetDate: spInitialAddendums.goal3.goal3d1MetDate ?? "",
        goal3d1OnGoing: spInitialAddendums.goal3.goal3d1OnGoing ?? false,
        goal3d1NotMet: spInitialAddendums.goal3.goal3d1NotMet ?? false,
        // --
        goal3d2: spInitialAddendums.goal3.goal3d2 ?? false,
        goal3d2Type: spInitialAddendums.goal3.goal3d2Type ?? "",
        goal3d2Long: spInitialAddendums.goal3.goal3d2Long ?? "",
        goal3d2Responsible: spInitialAddendums.goal3.goal3d2Responsible ?? "",
        goal3d2StartDate: spInitialAddendums.goal3.goal3d2StartDate ?? "",
        goal3d2TargetDate: spInitialAddendums.goal3.goal3d2TargetDate ?? "",
        goal3d2Met: spInitialAddendums.goal3.goal3d2Met ?? false,
        goal3d2MetDate: spInitialAddendums.goal3.goal3d2MetDate ?? "",
        goal3d2OnGoing: spInitialAddendums.goal3.goal3d2OnGoing ?? false,
        goal3d2NotMet: spInitialAddendums.goal3.goal3d2NotMet ?? false,
        //  --
        goal3d3: spInitialAddendums.goal3.goal3d3 ?? false,
        goal3d3Type: spInitialAddendums.goal3.goal3d3Type ?? "",
        goal3d3Long: spInitialAddendums.goal3.goal3d3Long ?? "",
        goal3d3Responsible: spInitialAddendums.goal3.goal3d3Responsible ?? "",
        goal3d3StartDate: spInitialAddendums.goal3.goal3d3StartDate ?? "",
        goal3d3TargetDate: spInitialAddendums.goal3.goal3d3TargetDate ?? "",
        goal3d3Met: spInitialAddendums.goal3.goal3d3Met ?? false,
        goal3d3MetDate: spInitialAddendums.goal3.goal3d3MetDate ?? "",
        goal3d3OnGoing: spInitialAddendums.goal3.goal3d3OnGoing ?? false,
        goal3d3NotMet: spInitialAddendums.goal3.goal3d3NotMet ?? false,
        // --
        goal3d4: spInitialAddendums.goal3.goal3d4 ?? false,
        goal3d4Type: spInitialAddendums.goal3.goal3d4Type ?? "",
        goal3d4Long: spInitialAddendums.goal3.goal3d4Long ?? "",
        goal3d4Responsible: spInitialAddendums.goal3.goal3d4Responsible ?? "",
        goal3d4StartDate: spInitialAddendums.goal3.goal3d4StartDate ?? "",
        goal3d4TargetDate: spInitialAddendums.goal3.goal3d4TargetDate ?? "",
        goal3d4Met: spInitialAddendums.goal3.goal3d4Met ?? false,
        goal3d4MetDate: spInitialAddendums.goal3.goal3d4MetDate ?? "",
        goal3d4OnGoing: spInitialAddendums.goal3.goal3d4OnGoing ?? false,
        goal3d4NotMet: spInitialAddendums.goal3.goal3d4NotMet ?? false,
        // --
        goal3d5: spInitialAddendums.goal3.goal3d5 ?? false,
        goal3d5Type: spInitialAddendums.goal3.goal3d5Type ?? "",
        goal3d5Long: spInitialAddendums.goal3.goal3d5Long ?? "",
        goal3d5Responsible: spInitialAddendums.goal3.goal3d5Responsible ?? "",
        goal3d5StartDate: spInitialAddendums.goal3.goal3d5StartDate ?? "",
        goal3d5TargetDate: spInitialAddendums.goal3.goal3d5TargetDate ?? "",
        goal3d5Met: spInitialAddendums.goal3.goal3d5Met ?? false,
        goal3d5MetDate: spInitialAddendums.goal3.goal3d5MetDate ?? "",
        goal3d5OnGoing: spInitialAddendums.goal3.goal3d5OnGoing ?? false,
        goal3d5NotMet: spInitialAddendums.goal3.goal3d5NotMet ?? false,
        // --
        goal3d6: spInitialAddendums.goal3.goal3d6 ?? false,
        goal3d6Type: spInitialAddendums.goal3.goal3d6Type ?? "",
        goal3d6Long: spInitialAddendums.goal3.goal3d6Long ?? "",
        goal3d6Responsible: spInitialAddendums.goal3.goal3d6Responsible ?? "",
        goal3d6StartDate: spInitialAddendums.goal3.goal3d6StartDate ?? "",
        goal3d6TargetDate: spInitialAddendums.goal3.goal3d6TargetDate ?? "",
        goal3d6Met: spInitialAddendums.goal3.goal3d6Met ?? false,
        goal3d6MetDate: spInitialAddendums.goal3.goal3d6MetDate ?? "",
        goal3d6OnGoing: spInitialAddendums.goal3.goal3d6OnGoing ?? false,
        goal3d6NotMet: spInitialAddendums.goal3.goal3d6NotMet ?? false,
        // --
        goal3d7: spInitialAddendums.goal3.goal3d7 ?? false,
        goal3d7Type: spInitialAddendums.goal3.goal3d7Type ?? "",
        goal3d7Text: spInitialAddendums.goal3.goal3d7Text ?? "",
        goal3d7Long: spInitialAddendums.goal3.goal3d7Long ?? "",
        goal3d7Responsible: spInitialAddendums.goal3.goal3d7Responsible ?? "",
        goal3d7StartDate: spInitialAddendums.goal3.goal3d7StartDate ?? "",
        goal3d7TargetDate: spInitialAddendums.goal3.goal3d7TargetDate ?? "",
        goal3d7Met: spInitialAddendums.goal3.goal3d7Met ?? false,
        goal3d7MetDate: spInitialAddendums.goal3.goal3d7MetDate ?? "",
        goal3d7OnGoing: spInitialAddendums.goal3.goal3d7OnGoing ?? false,
        goal3d7NotMet: spInitialAddendums.goal3.goal3d7NotMet ?? false,
        // --
        goal3e: spInitialAddendums.goal3.goal3e ?? false,
        goal3eType: spInitialAddendums.goal3.goal3eType ?? "",
        goal3eLong: spInitialAddendums.goal3.goal3eLong ?? "",
        goal3eResponsible: spInitialAddendums.goal3.goal3eResponsible ?? "",
        goal3eStartDate: spInitialAddendums.goal3.goal3eStartDate ?? "",
        goal3eTargetDate: spInitialAddendums.goal3.goal3eTargetDate ?? "",
        goal3eMet: spInitialAddendums.goal3.goal3eMet ?? false,
        goal3eMetDate: spInitialAddendums.goal3.goal3eMetDate ?? "",
        goal3eOnGoing: spInitialAddendums.goal3.goal3eOnGoing ?? false,
        goal3eNotMet: spInitialAddendums.goal3.goal3eNotMet ?? false,
        // --
        goal3f: spInitialAddendums.goal3.goal3f ?? false,
        goal3fType: spInitialAddendums.goal3.goal3fType ?? "",
        goal3fLong: spInitialAddendums.goal3.goal3fLong ?? "",
        goal3fResponsible: spInitialAddendums.goal3.goal3fResponsible ?? "",
        goal3fStartDate: spInitialAddendums.goal3.goal3fStartDate ?? "",
        goal3fTargetDate: spInitialAddendums.goal3.goal3fTargetDate ?? "",
        goal3fMet: spInitialAddendums.goal3.goal3fMet ?? false,
        goal3fMetDate: spInitialAddendums.goal3.goal3fMetDate ?? "",
        goal3fOnGoing: spInitialAddendums.goal3.goal3fOnGoing ?? false,
        goal3fNotMet: spInitialAddendums.goal3.goal3fNotMet ?? false,
    };
};
 const initializeGoal4 = (spInitialAddendums) => {
    return {
        goal4a: spInitialAddendums.goal4.goal4a ?? false,
        goal4aType: spInitialAddendums.goal4.goal4aType ?? "",
        goal4aLong: spInitialAddendums.goal4.goal4aLong ?? "",
        goal4aResponsible: spInitialAddendums.goal4.goal4aResponsible ?? "",
        goal4aStartDate: spInitialAddendums.goal4.goal4aStartDate ?? "",
        goal4aTargetDate: spInitialAddendums.goal4.goal4aTargetDate ?? "",
        goal4aMet: spInitialAddendums.goal4.goal4aMet ?? false,
        goal4aMetDate: spInitialAddendums.goal4.goal4aMetDate ?? "",
        goal4aOnGoing: spInitialAddendums.goal4.goal4aOnGoing ?? false,
        goal4aNotMet: spInitialAddendums.goal4.goal4aNotMet ?? false,
        // --
        goal4b: spInitialAddendums.goal4.goal4b ?? false,
        goal4bType: spInitialAddendums.goal4.goal4bType ?? "",
        goal4bLong: spInitialAddendums.goal4.goal4bLong ?? "",
        goal4bResponsible: spInitialAddendums.goal4.goal4bResponsible ?? "",
        goal4bStartDate: spInitialAddendums.goal4.goal4bStartDate ?? "",
        goal4bTargetDate: spInitialAddendums.goal4.goal4bTargetDate ?? "",
        goal4bMet: spInitialAddendums.goal4.goal4bMet ?? false,
        goal4bMetDate: spInitialAddendums.goal4.goal4bMetDate ?? "",
        goal4bOnGoing: spInitialAddendums.goal4.goal4bOnGoing ?? false,
        goal4bNotMet: spInitialAddendums.goal4.goal4bNotMet ?? false,
        // --
        goal4c: spInitialAddendums.goal4.goal4c ?? false,
        goal4cType: spInitialAddendums.goal4.goal4cType ?? "",
        goal4cLong: spInitialAddendums.goal4.goal4cLong ?? "",
        goal4cResponsible: spInitialAddendums.goal4.goal4cResponsible ?? "",
        goal4cStartDate: spInitialAddendums.goal4.goal4cStartDate ?? "",
        goal4cTargetDate: spInitialAddendums.goal4.goal4cTargetDate ?? "",
        goal4cMet: spInitialAddendums.goal4.goal4cMet ?? false,
        goal4cMetDate: spInitialAddendums.goal4.goal4cMetDate ?? "",
        goal4cOnGoing: spInitialAddendums.goal4.goal4cOnGoing ?? false,
        goal4cNotMet: spInitialAddendums.goal4.goal4cNotMet ?? false,
        // --
        goal4d: spInitialAddendums.goal4.goal4d ?? false,
        goal4dType: spInitialAddendums.goal4.goal4dType ?? "",
        goal4dLong: spInitialAddendums.goal4.goal4dLong ?? "",
        goal4dResponsible: spInitialAddendums.goal4.goal4dResponsible ?? "",
        goal4dStartDate: spInitialAddendums.goal4.goal4dStartDate ?? "",
        goal4dTargetDate: spInitialAddendums.goal4.goal4dTargetDate ?? "",
        goal4dMet: spInitialAddendums.goal4.goal4dMet ?? false,
        goal4dMetDate: spInitialAddendums.goal4.goal4dMetDate ?? "",
        goal4dOnGoing: spInitialAddendums.goal4.goal4dOnGoing ?? false,
        goal4dNotMet: spInitialAddendums.goal4.goal4dNotMet ?? false,
        // --
        goal4e: spInitialAddendums.goal4.goal4e ?? false,
        goal4eType: spInitialAddendums.goal4.goal4eType ?? "",
        goal4eLong: spInitialAddendums.goal4.goal4eLong ?? "",
        goal4eResponsible: spInitialAddendums.goal4.goal4eResponsible ?? "",
        goal4eStartDate: spInitialAddendums.goal4.goal4eStartDate ?? "",
        goal4eTargetDate: spInitialAddendums.goal4.goal4eTargetDate ?? "",
        goal4eMet: spInitialAddendums.goal4.goal4eMet ?? false,
        goal4eMetDate: spInitialAddendums.goal4.goal4eMetDate ?? "",
        goal4eOnGoing: spInitialAddendums.goal4.goal4eOnGoing ?? false,
        goal4eNotMet: spInitialAddendums.goal4.goal4eNotMet ?? false,
        // --
        goal4f: spInitialAddendums.goal4.goal4f ?? false,
        goal4fType: spInitialAddendums.goal4.goal4fType ?? "",
        goal4fLong: spInitialAddendums.goal4.goal4fLong ?? "",
        goal4fResponsible: spInitialAddendums.goal4.goal4fResponsible ?? "",
        goal4fStartDate: spInitialAddendums.goal4.goal4fStartDate ?? "",
        goal4fTargetDate: spInitialAddendums.goal4.goal4fTargetDate ?? "",
        goal4fMet: spInitialAddendums.goal4.goal4fMet ?? false,
        goal4fMetDate: spInitialAddendums.goal4.goal4fMetDate ?? "",
        goal4fOnGoing: spInitialAddendums.goal4.goal4fOnGoing ?? false,
        goal4fNotMet: spInitialAddendums.goal4.goal4fNotMet ?? false,
    };
};
 const initializeGoal5 = (spInitialAddendums) => {
    return {
        goal5a: spInitialAddendums.goal5.goal5a ?? false,
        goal5aType: spInitialAddendums.goal5.goal5aType ?? "",
        goal5aLong: spInitialAddendums.goal5.goal5aLong ?? "",
        goal5aResponsible: spInitialAddendums.goal5.goal5aResponsible ?? "",
        goal5aStartDate: spInitialAddendums.goal5.goal5aStartDate ?? "",
        goal5aTargetDate: spInitialAddendums.goal5.goal5aTargetDate ?? "",
        goal5aMet: spInitialAddendums.goal5.goal5aMet ?? false,
        goal5aMetDate: spInitialAddendums.goal5.goal5aMetDate ?? "",
        goal5aOnGoing: spInitialAddendums.goal5.goal5aOnGoing ?? false,
        goal5aNotMet: spInitialAddendums.goal5.goal5aNotMet ?? false,
        // --
        goal5b: spInitialAddendums.goal5.goal5b ?? false,
        goal5bType: spInitialAddendums.goal5.goal5bType ?? "",
        goal5bLong: spInitialAddendums.goal5.goal5bLong ?? "",
        goal5bResponsible: spInitialAddendums.goal5.goal5bResponsible ?? "",
        goal5bStartDate: spInitialAddendums.goal5.goal5bStartDate ?? "",
        goal5bTargetDate: spInitialAddendums.goal5.goal5bTargetDate ?? "",
        goal5bMet: spInitialAddendums.goal5.goal5bMet ?? false,
        goal5bMetDate: spInitialAddendums.goal5.goal5bMetDate ?? "",
        goal5bOnGoing: spInitialAddendums.goal5.goal5bOnGoing ?? false,
        goal5bNotMet: spInitialAddendums.goal5.goal5bNotMet ?? false,
        // --
        goal5c: spInitialAddendums.goal5.goal5c ?? false,
        goal5cType: spInitialAddendums.goal5.goal5cType ?? "",
        goal5cLong: spInitialAddendums.goal5.goal5cLong ?? "",
        goal5cResponsible: spInitialAddendums.goal5.goal5cResponsible ?? "",
        goal5cStartDate: spInitialAddendums.goal5.goal5cStartDate ?? "",
        goal5cTargetDate: spInitialAddendums.goal5.goal5cTargetDate ?? "",
        goal5cMet: spInitialAddendums.goal5.goal5cMet ?? false,
        goal5cMetDate: spInitialAddendums.goal5.goal5cMetDate ?? "",
        goal5cOnGoing: spInitialAddendums.goal5.goal5cOnGoing ?? false,
        goal5cNotMet: spInitialAddendums.goal5.goal5cNotMet ?? false,
        // --
        goal5d: spInitialAddendums.goal5.goal5d ?? false,
        goal5dType: spInitialAddendums.goal5.goal5dType ?? "",
        goal5dLong: spInitialAddendums.goal5.goal5dLong ?? "",
        goal5dResponsible: spInitialAddendums.goal5.goal5dResponsible ?? "",
        goal5dStartDate: spInitialAddendums.goal5.goal5dStartDate ?? "",
        goal5dTargetDate: spInitialAddendums.goal5.goal5dTargetDate ?? "",
        goal5dMet: spInitialAddendums.goal5.goal5dMet ?? false,
        goal5dMetDate: spInitialAddendums.goal5.goal5dMetDate ?? "",
        goal5dOnGoing: spInitialAddendums.goal5.goal5dOnGoing ?? false,
        goal5dNotMet: spInitialAddendums.goal5.goal5dNotMet ?? false,
        // --
        goal5e: spInitialAddendums.goal5.goal5e ?? false,
        goal5eType: spInitialAddendums.goal5.goal5eType ?? "",
        goal5eLong: spInitialAddendums.goal5.goal5eLong ?? "",
        goal5eResponsible: spInitialAddendums.goal5.goal5eResponsible ?? "",
        goal5eStartDate: spInitialAddendums.goal5.goal5eStartDate ?? "",
        goal5eTargetDate: spInitialAddendums.goal5.goal5eTargetDate ?? "",
        goal5eMet: spInitialAddendums.goal5.goal5eMet ?? false,
        goal5eMetDate: spInitialAddendums.goal5.goal5eMetDate ?? "",
        goal5eOnGoing: spInitialAddendums.goal5.goal5eOnGoing ?? false,
        goal5eNotMet: spInitialAddendums.goal5.goal5eNotMet ?? false,
    };
};
 const initializeGoal6 = (spInitialAddendums) => {
    return {
        goal6a1: spInitialAddendums.goal6.goal6a1 ?? false,
        goal6a1Type: spInitialAddendums.goal6.goal6a1Type ?? "",
        goal6a1Long: spInitialAddendums.goal6.goal6a1Long ?? "",
        goal6a1Responsible: spInitialAddendums.goal6.goal6a1Responsible ?? "",
        goal6a1StartDate: spInitialAddendums.goal6.goal6a1StartDate ?? "",
        goal6a1TargetDate: spInitialAddendums.goal6.goal6a1TargetDate ?? "",
        goal6a1Met: spInitialAddendums.goal6.goal6a1Met ?? false,
        goal6a1MetDate: spInitialAddendums.goal6.goal6a1MetDate ?? "",
        goal6a1OnGoing: spInitialAddendums.goal6.goal6a1OnGoing ?? false,
        goal6a1NotMet: spInitialAddendums.goal6.goal6a1NotMet ?? false,
        // --
        goal6a2: spInitialAddendums.goal6.goal6a2 ?? false,
        goal6a2Type: spInitialAddendums.goal6.goal6a2Type ?? "",
        goal6a2Long: spInitialAddendums.goal6.goal6a2Long ?? "",
        goal6a2Responsible: spInitialAddendums.goal6.goal6a2Responsible ?? "",
        goal6a2StartDate: spInitialAddendums.goal6.goal6a2StartDate ?? "",
        goal6a2TargetDate: spInitialAddendums.goal6.goal6a2TargetDate ?? "",
        goal6a2Met: spInitialAddendums.goal6.goal6a2Met ?? false,
        goal6a2MetDate: spInitialAddendums.goal6.goal6a2MetDate ?? "",
        goal6a2OnGoing: spInitialAddendums.goal6.goal6a2OnGoing ?? false,
        goal6a2NotMet: spInitialAddendums.goal6.goal6a2NotMet ?? false,
        // --
        goal6a3: spInitialAddendums.goal6.goal6a3 ?? false,
        goal6a3Type: spInitialAddendums.goal6.goal6a3Type ?? "",
        goal6a3Long: spInitialAddendums.goal6.goal6a3Long ?? "",
        goal6a3Responsible: spInitialAddendums.goal6.goal6a3Responsible ?? "",
        goal6a3StartDate: spInitialAddendums.goal6.goal6a3StartDate ?? "",
        goal6a3TargetDate: spInitialAddendums.goal6.goal6a3TargetDate ?? "",
        goal6a3Met: spInitialAddendums.goal6.goal6a3Met ?? false,
        goal6a3MetDate: spInitialAddendums.goal6.goal6a3MetDate ?? "",
        goal6a3OnGoing: spInitialAddendums.goal6.goal6a3OnGoing ?? false,
        goal6a3NotMet: spInitialAddendums.goal6.goal6a3NotMet ?? false,
        // --
        goal6a4: spInitialAddendums.goal6.goal6a4 ?? false,
        goal6a4Type: spInitialAddendums.goal6.goal6a4Type ?? "",
        goal6a4Long: spInitialAddendums.goal6.goal6a4Long ?? "",
        goal6a4Responsible: spInitialAddendums.goal6.goal6a4Responsible ?? "",
        goal6a4StartDate: spInitialAddendums.goal6.goal6a4StartDate ?? "",
        goal6a4TargetDate: spInitialAddendums.goal6.goal6a4TargetDate ?? "",
        goal6a4Met: spInitialAddendums.goal6.goal6a4Met ?? false,
        goal6a4MetDate: spInitialAddendums.goal6.goal6a4MetDate ?? "",
        goal6a4OnGoing: spInitialAddendums.goal6.goal6a4OnGoing ?? false,
        goal6a4NotMet: spInitialAddendums.goal6.goal6a4NotMet ?? false,
        // --
        goal6a5: spInitialAddendums.goal6.goal6a5 ?? false,
        goal6a5Type: spInitialAddendums.goal6.goal6a5Type ?? "",
        goal6a5Long: spInitialAddendums.goal6.goal6a5Long ?? "",
        goal6a5Responsible: spInitialAddendums.goal6.goal6a5Responsible ?? "",
        goal6a5StartDate: spInitialAddendums.goal6.goal6a5StartDate ?? "",
        goal6a5TargetDate: spInitialAddendums.goal6.goal6a5TargetDate ?? "",
        goal6a5Met: spInitialAddendums.goal6.goal6a5Met ?? false,
        goal6a5MetDate: spInitialAddendums.goal6.goal6a5MetDate ?? "",
        goal6a5OnGoing: spInitialAddendums.goal6.goal6a5OnGoing ?? false,
        goal6a5NotMet: spInitialAddendums.goal6.goal6a5NotMet ?? false,
        // --
        goal6b: spInitialAddendums.goal6.goal6b ?? false,
        goal6bType: spInitialAddendums.goal6.goal6bType ?? "",
        goal6bLong: spInitialAddendums.goal6.goal6bLong ?? "",
        goal6bResponsible: spInitialAddendums.goal6.goal6bResponsible ?? "",
        goal6bStartDate: spInitialAddendums.goal6.goal6bStartDate ?? "",
        goal6bTargetDate: spInitialAddendums.goal6.goal6bTargetDate ?? "",
        goal6bMet: spInitialAddendums.goal6.goal6bMet ?? false,
        goal6bMetDate: spInitialAddendums.goal6.goal6bMetDate ?? "",
        goal6bOnGoing: spInitialAddendums.goal6.goal6bOnGoing ?? false,
        goal6bNotMet: spInitialAddendums.goal6.goal6bNotMet ?? false,
        // --
        goal6c: spInitialAddendums.goal6.goal6c ?? false,
        goal6cType: spInitialAddendums.goal6.goal6cType ?? "",
        goal6cLong: spInitialAddendums.goal6.goal6cLong ?? "",
        goal6cResponsible: spInitialAddendums.goal6.goal6cResponsible ?? "",
        goal6cStartDate: spInitialAddendums.goal6.goal6cStartDate ?? "",
        goal6cTargetDate: spInitialAddendums.goal6.goal6cTargetDate ?? "",
        goal6cMet: spInitialAddendums.goal6.goal6cMet ?? false,
        goal6cMetDate: spInitialAddendums.goal6.goal6cMetDate ?? "",
        goal6cOnGoing: spInitialAddendums.goal6.goal6cOnGoing ?? false,
        goal6cNotMet: spInitialAddendums.goal6.goal6cNotMet ?? false,
        // --
        goal6d: spInitialAddendums.goal6.goal6d ?? false,
        goal6dType: spInitialAddendums.goal6.goal6dType ?? "",
        goal6dLong: spInitialAddendums.goal6.goal6dLong ?? "",
        goal6dResponsible: spInitialAddendums.goal6.goal6dResponsible ?? "",
        goal6dStartDate: spInitialAddendums.goal6.goal6dStartDate ?? "",
        goal6dTargetDate: spInitialAddendums.goal6.goal6dTargetDate ?? "",
        goal6dMet: spInitialAddendums.goal6.goal6dMet ?? false,
        goal6dMetDate: spInitialAddendums.goal6.goal6dMetDate ?? "",
        goal6dOnGoing: spInitialAddendums.goal6.goal6dOnGoing ?? false,
        goal6dNotMet: spInitialAddendums.goal6.goal6dNotMet ?? false,
        // --
        goal6e: spInitialAddendums.goal6.goal6e ?? false,
        goal6eType: spInitialAddendums.goal6.goal6eType ?? "",
        goal6eLong: spInitialAddendums.goal6.goal6eLong ?? "",
        goal6eResponsible: spInitialAddendums.goal6.goal6eResponsible ?? "",
        goal6eStartDate: spInitialAddendums.goal6.goal6eStartDate ?? "",
        goal6eTargetDate: spInitialAddendums.goal6.goal6eTargetDate ?? "",
        goal6eMet: spInitialAddendums.goal6.goal6eMet ?? false,
        goal6eMetDate: spInitialAddendums.goal6.goal6eMetDate ?? "",
        goal6eOnGoing: spInitialAddendums.goal6.goal6eOnGoing ?? false,
        goal6eNotMet: spInitialAddendums.goal6.goal6eNotMet ?? false,
        // --
        goal6f: spInitialAddendums.goal6.goal6f ?? false,
        goal6fType: spInitialAddendums.goal6.goal6fType ?? "",
        goal6fLong: spInitialAddendums.goal6.goal6fLong ?? "",
        goal6fResponsible: spInitialAddendums.goal6.goal6fResponsible ?? "",
        goal6fStartDate: spInitialAddendums.goal6.goal6fStartDate ?? "",
        goal6fTargetDate: spInitialAddendums.goal6.goal6fTargetDate ?? "",
        goal6fMet: spInitialAddendums.goal6.goal6fMet ?? false,
        goal6fMetDate: spInitialAddendums.goal6.goal6fMetDate ?? "",
        goal6fOnGoing: spInitialAddendums.goal6.goal6fOnGoing ?? false,
        goal6fNotMet: spInitialAddendums.goal6.goal6fNotMet ?? false,
    };
};
 const initializeGoal7 = (spInitialAddendums) => {
    return {
        goal7a: spInitialAddendums.goal7.goal7a ?? false,
        goal7aType: spInitialAddendums.goal7.goal7aType ?? "",
        goal7aLong: spInitialAddendums.goal7.goal7aLong ?? "",
        goal7aResponsible: spInitialAddendums.goal7.goal7aResponsible ?? "",
        goal7aStartDate: spInitialAddendums.goal7.goal7aStartDate ?? "",
        goal7aTargetDate: spInitialAddendums.goal7.goal7aTargetDate ?? "",
        goal7aMet: spInitialAddendums.goal7.goal7aMet ?? false,
        goal7aMetDate: spInitialAddendums.goal7.goal7aMetDate ?? "",
        goal7aOnGoing: spInitialAddendums.goal7.goal7aOnGoing ?? false,
        goal7aNotMet: spInitialAddendums.goal7.goal7aNotMet ?? false,
        // --
        goal7b1: spInitialAddendums.goal7.goal7b1 ?? false,
        goal7b1Type: spInitialAddendums.goal7.goal7b1Type ?? "",
        goal7b1Long: spInitialAddendums.goal7.goal7b1Long ?? "",
        goal7b1Responsible: spInitialAddendums.goal7.goal7b1Responsible ?? "",
        goal7b1StartDate: spInitialAddendums.goal7.goal7b1StartDate ?? "",
        goal7b1TargetDate: spInitialAddendums.goal7.goal7b1TargetDate ?? "",
        goal7b1Met: spInitialAddendums.goal7.goal7b1Met ?? false,
        goal7b1MetDate: spInitialAddendums.goal7.goal7b1MetDate ?? "",
        goal7b1OnGoing: spInitialAddendums.goal7.goal7b1OnGoing ?? false,
        goal7b1NotMet: spInitialAddendums.goal7.goal7b1NotMet ?? false,
        // --
        goal7b2: spInitialAddendums.goal7.goal7b2 ?? false,
        goal7b2Type: spInitialAddendums.goal7.goal7b2Type ?? "",
        goal7b2Long: spInitialAddendums.goal7.goal7b2Long ?? "",
        goal7b2Responsible: spInitialAddendums.goal7.goal7b2Responsible ?? "",
        goal7b2StartDate: spInitialAddendums.goal7.goal7b2StartDate ?? "",
        goal7b2TargetDate: spInitialAddendums.goal7.goal7b2TargetDate ?? "",
        goal7b2Met: spInitialAddendums.goal7.goal7b2Met ?? false,
        goal7b2MetDate: spInitialAddendums.goal7.goal7b2MetDate ?? "",
        goal7b2OnGoing: spInitialAddendums.goal7.goal7b2OnGoing ?? false,
        goal7b2NotMet: spInitialAddendums.goal7.goal7b2NotMet ?? false,
        // --
        goal7b3: spInitialAddendums.goal7.goal7b3 ?? false,
        goal7b3Type: spInitialAddendums.goal7.goal7b3Type ?? "",
        goal7b3Long: spInitialAddendums.goal7.goal7b3Long ?? "",
        goal7b3Responsible: spInitialAddendums.goal7.goal7b3Responsible ?? "",
        goal7b3StartDate: spInitialAddendums.goal7.goal7b3StartDate ?? "",
        goal7b3TargetDate: spInitialAddendums.goal7.goal7b3TargetDate ?? "",
        goal7b3Met: spInitialAddendums.goal7.goal7b3Met ?? false,
        goal7b3MetDate: spInitialAddendums.goal7.goal7b3MetDate ?? "",
        goal7b3OnGoing: spInitialAddendums.goal7.goal7b3OnGoing ?? false,
        goal7b3NotMet: spInitialAddendums.goal7.goal7b3NotMet ?? false,
        // --
        goal7b4: spInitialAddendums.goal7.goal7b4 ?? false,
        goal7b4Type: spInitialAddendums.goal7.goal7b4Type ?? "",
        goal7b4Long: spInitialAddendums.goal7.goal7b4Long ?? "",
        goal7b4Responsible: spInitialAddendums.goal7.goal7b4Responsible ?? "",
        goal7b4StartDate: spInitialAddendums.goal7.goal7b4StartDate ?? "",
        goal7b4TargetDate: spInitialAddendums.goal7.goal7b4TargetDate ?? "",
        goal7b4Met: spInitialAddendums.goal7.goal7b4Met ?? false,
        goal7b4MetDate: spInitialAddendums.goal7.goal7b4MetDate ?? "",
        goal7b4OnGoing: spInitialAddendums.goal7.goal7b4OnGoing ?? false,
        goal7b4NotMet: spInitialAddendums.goal7.goal7b4NotMet ?? false,
        // --
        goal7c: spInitialAddendums.goal7.goal7c ?? false,
        goal7cType: spInitialAddendums.goal7.goal7cType ?? "",
        goal7cLong: spInitialAddendums.goal7.goal7cLong ?? "",
        goal7cResponsible: spInitialAddendums.goal7.goal7cResponsible ?? "",
        goal7cStartDate: spInitialAddendums.goal7.goal7cStartDate ?? "",
        goal7cTargetDate: spInitialAddendums.goal7.goal7cTargetDate ?? "",
        goal7cMet: spInitialAddendums.goal7.goal7cMet ?? false,
        goal7cMetDate: spInitialAddendums.goal7.goal7cMetDate ?? "",
        goal7cOnGoing: spInitialAddendums.goal7.goal7cOnGoing ?? false,
        goal7cNotMet: spInitialAddendums.goal7.goal7cNotMet ?? false,
        // --
        goal7d: spInitialAddendums.goal7.goal7d ?? false,
        goal7dType: spInitialAddendums.goal7.goal7dType ?? "",
        goal7dLong: spInitialAddendums.goal7.goal7dLong ?? "",
        goal7dResponsible: spInitialAddendums.goal7.goal7dResponsible ?? "",
        goal7dStartDate: spInitialAddendums.goal7.goal7dStartDate ?? "",
        goal7dTargetDate: spInitialAddendums.goal7.goal7dTargetDate ?? "",
        goal7dMet: spInitialAddendums.goal7.goal7dMet ?? false,
        goal7dMetDate: spInitialAddendums.goal7.goal7dMetDate ?? "",
        goal7dOnGoing: spInitialAddendums.goal7.goal7dOnGoing ?? false,
        goal7dNotMet: spInitialAddendums.goal7.goal7dNotMet ?? false,
        // --
        goal7e1: spInitialAddendums.goal7.goal7e1 ?? false,
        goal7e1Type: spInitialAddendums.goal7.goal7e1Type ?? "",
        goal7e1Long: spInitialAddendums.goal7.goal7e1Long ?? "",
        goal7e1Responsible: spInitialAddendums.goal7.goal7e1Responsible ?? "",
        goal7e1StartDate: spInitialAddendums.goal7.goal7e1StartDate ?? "",
        goal7e1TargetDate: spInitialAddendums.goal7.goal7e1TargetDate ?? "",
        goal7e1Met: spInitialAddendums.goal7.goal7e1Met ?? false,
        goal7e1MetDate: spInitialAddendums.goal7.goal7e1MetDate ?? "",
        goal7e1OnGoing: spInitialAddendums.goal7.goal7e1OnGoing ?? false,
        goal7e1NotMet: spInitialAddendums.goal7.goal7e1NotMet ?? false,
        // --
        goal7e2: spInitialAddendums.goal7.goal7e2 ?? false,
        goal7e2Type: spInitialAddendums.goal7.goal7e2Type ?? "",
        goal7e2Long: spInitialAddendums.goal7.goal7e2Long ?? "",
        goal7e2Responsible: spInitialAddendums.goal7.goal7e2Responsible ?? "",
        goal7e2StartDate: spInitialAddendums.goal7.goal7e2StartDate ?? "",
        goal7e2TargetDate: spInitialAddendums.goal7.goal7e2TargetDate ?? "",
        goal7e2Met: spInitialAddendums.goal7.goal7e2Met ?? false,
        goal7e2MetDate: spInitialAddendums.goal7.goal7e2MetDate ?? "",
        goal7e2OnGoing: spInitialAddendums.goal7.goal7e2OnGoing ?? false,
        goal7e2NotMet: spInitialAddendums.goal7.goal7e2NotMet ?? false,
        // --
        goal7e3: spInitialAddendums.goal7.goal7e3 ?? false,
        goal7e3Type: spInitialAddendums.goal7.goal7e3Type ?? "",
        goal7e3Long: spInitialAddendums.goal7.goal7e3Long ?? "",
        goal7e3Responsible: spInitialAddendums.goal7.goal7e3Responsible ?? "",
        goal7e3StartDate: spInitialAddendums.goal7.goal7e3StartDate ?? "",
        goal7e3TargetDate: spInitialAddendums.goal7.goal7e3TargetDate ?? "",
        goal7e3Met: spInitialAddendums.goal7.goal7e3Met ?? false,
        goal7e3MetDate: spInitialAddendums.goal7.goal7e3MetDate ?? "",
        goal7e3OnGoing: spInitialAddendums.goal7.goal7e3OnGoing ?? false,
        goal7e3NotMet: spInitialAddendums.goal7.goal7e3NotMet ?? false,
        // --
        goal7e4: spInitialAddendums.goal7.goal7e4 ?? false,
        goal7e4Type: spInitialAddendums.goal7.goal7e4Type ?? "",
        goal7e4Long: spInitialAddendums.goal7.goal7e4Long ?? "",
        goal7e4Responsible: spInitialAddendums.goal7.goal7e4Responsible ?? "",
        goal7e4StartDate: spInitialAddendums.goal7.goal7e4StartDate ?? "",
        goal7e4TargetDate: spInitialAddendums.goal7.goal7e4TargetDate ?? "",
        goal7e4Met: spInitialAddendums.goal7.goal7e4Met ?? false,
        goal7e4MetDate: spInitialAddendums.goal7.goal7e4MetDate ?? "",
        goal7e4OnGoing: spInitialAddendums.goal7.goal7e4OnGoing ?? false,
        goal7e4NotMet: spInitialAddendums.goal7.goal7e4NotMet ?? false,
        // --
        goal7e5: spInitialAddendums.goal7.goal7e5 ?? false,
        goal7e5Type: spInitialAddendums.goal7.goal7e5Type ?? "",
        goal7e5Long: spInitialAddendums.goal7.goal7e5Long ?? "",
        goal7e5Responsible: spInitialAddendums.goal7.goal7e5Responsible ?? "",
        goal7e5StartDate: spInitialAddendums.goal7.goal7e5StartDate ?? "",
        goal7e5TargetDate: spInitialAddendums.goal7.goal7e5TargetDate ?? "",
        goal7e5Met: spInitialAddendums.goal7.goal7e5Met ?? false,
        goal7e5MetDate: spInitialAddendums.goal7.goal7e5MetDate ?? "",
        goal7e5OnGoing: spInitialAddendums.goal7.goal7e5OnGoing ?? false,
        goal7e5NotMet: spInitialAddendums.goal7.goal7e5NotMet ?? false,
        // --
        goal7e6: spInitialAddendums.goal7.goal7e6 ?? false,
        goal7e6Type: spInitialAddendums.goal7.goal7e6Type ?? "",
        goal7e6Long: spInitialAddendums.goal7.goal7e6Long ?? "",
        goal7e6Responsible: spInitialAddendums.goal7.goal7e6Responsible ?? "",
        goal7e6StartDate: spInitialAddendums.goal7.goal7e6StartDate ?? "",
        goal7e6TargetDate: spInitialAddendums.goal7.goal7e6TargetDate ?? "",
        goal7e6Met: spInitialAddendums.goal7.goal7e6Met ?? false,
        goal7e6MetDate: spInitialAddendums.goal7.goal7e6MetDate ?? "",
        goal7e6OnGoing: spInitialAddendums.goal7.goal7e6OnGoing ?? false,
        goal7e6NotMet: spInitialAddendums.goal7.goal7e6NotMet ?? false,
        // --
        goal7e7: spInitialAddendums.goal7.goal7e7 ?? false,
        goal7e7Type: spInitialAddendums.goal7.goal7e7Type ?? "",
        goal7e7Text: spInitialAddendums.goal7.goal7e7Text ?? "",
        goal7e7Long: spInitialAddendums.goal7.goal7e7Long ?? "",
        goal7e7Responsible: spInitialAddendums.goal7.goal7e7Responsible ?? "",
        goal7e7StartDate: spInitialAddendums.goal7.goal7e7StartDate ?? "",
        goal7e7TargetDate: spInitialAddendums.goal7.goal7e7TargetDate ?? "",
        goal7e7Met: spInitialAddendums.goal7.goal7e7Met ?? false,
        goal7e7MetDate: spInitialAddendums.goal7.goal7e7MetDate ?? "",
        goal7e7OnGoing: spInitialAddendums.goal7.goal7e7OnGoing ?? false,
        goal7e7NotMet: spInitialAddendums.goal7.goal7e7NotMet ?? false,
    };
};
const initializeGoal8 = (spInitialAddendums) => {
    return {
        goal8a: spInitialAddendums.goal8.goal8a ?? false,
        goal8aType: spInitialAddendums.goal8.goal8aType ?? "",
        goal8aLong: spInitialAddendums.goal8.goal8aLong ?? "",
        goal8aResponsible: spInitialAddendums.goal8.goal8aResponsible ?? "",
        goal8aStartDate: spInitialAddendums.goal8.goal8aStartDate ?? "",
        goal8aTargetDate: spInitialAddendums.goal8.goal8aTargetDate ?? "",
        goal8aMet: spInitialAddendums.goal8.goal8aMet ?? false,
        goal8aMetDate: spInitialAddendums.goal8.goal8aMetDate ?? "",
        goal8aOnGoing: spInitialAddendums.goal8.goal8aOnGoing ?? false,
        goal8aNotMet: spInitialAddendums.goal8.goal8aNotMet ?? false,
        // --
        goal8b: spInitialAddendums.goal8.goal8b ?? false,
        goal8bType: spInitialAddendums.goal8.goal8bType ?? "",
        goal8bLong: spInitialAddendums.goal8.goal8bLong ?? "",
        goal8bResponsible: spInitialAddendums.goal8.goal8bResponsible ?? "",
        goal8bStartDate: spInitialAddendums.goal8.goal8bStartDate ?? "",
        goal8bTargetDate: spInitialAddendums.goal8.goal8bTargetDate ?? "",
        goal8bMet: spInitialAddendums.goal8.goal8bMet ?? false,
        goal8bMetDate: spInitialAddendums.goal8.goal8bMetDate ?? "",
        goal8bOnGoing: spInitialAddendums.goal8.goal8bOnGoing ?? false,
        goal8bNotMet: spInitialAddendums.goal8.goal8bNotMet ?? false,
        // --
        goal8c: spInitialAddendums.goal8.goal8c ?? false,
        goal8cType: spInitialAddendums.goal8.goal8cType ?? "",
        goal8cLong: spInitialAddendums.goal8.goal8cLong ?? "",
        goal8cResponsible: spInitialAddendums.goal8.goal8cResponsible ?? "",
        goal8cStartDate: spInitialAddendums.goal8.goal8cStartDate ?? "",
        goal8cTargetDate: spInitialAddendums.goal8.goal8cTargetDate ?? "",
        goal8cMet: spInitialAddendums.goal8.goal8cMet ?? false,
        goal8cMetDate: spInitialAddendums.goal8.goal8cMetDate ?? "",
        goal8cOnGoing: spInitialAddendums.goal8.goal8cOnGoing ?? false,
        goal8cNotMet: spInitialAddendums.goal8.goal8cNotMet ?? false,
        // --
        goal8d: spInitialAddendums.goal8.goal8d ?? false,
        goal8dType: spInitialAddendums.goal8.goal8dType ?? "",
        goal8dLong: spInitialAddendums.goal8.goal8dLong ?? "",
        goal8dResponsible: spInitialAddendums.goal8.goal8dResponsible ?? "",
        goal8dStartDate: spInitialAddendums.goal8.goal8dStartDate ?? "",
        goal8dTargetDate: spInitialAddendums.goal8.goal8dTargetDate ?? "",
        goal8dMet: spInitialAddendums.goal8.goal8dMet ?? false,
        goal8dMetDate: spInitialAddendums.goal8.goal8dMetDate ?? "",
        goal8dOnGoing: spInitialAddendums.goal8.goal8dOnGoing ?? false,
        goal8dNotMet: spInitialAddendums.goal8.goal8dNotMet ?? false,
        // --
        goal8e: spInitialAddendums.goal8.goal8e ?? false,
        goal8eType: spInitialAddendums.goal8.goal8eType ?? "",
        goal8eText: spInitialAddendums.goal8.goal8eText ?? "",
        goal8eLong: spInitialAddendums.goal8.goal8eLong ?? "",
        goal8eResponsible: spInitialAddendums.goal8.goal8eResponsible ?? "",
        goal8eStartDate: spInitialAddendums.goal8.goal8eStartDate ?? "",
        goal8eTargetDate: spInitialAddendums.goal8.goal8eTargetDate ?? "",
        goal8eMet: spInitialAddendums.goal8.goal8eMet ?? false,
        goal8eMetDate: spInitialAddendums.goal8.goal8eMetDate ?? "",
        goal8eOnGoing: spInitialAddendums.goal8.goal8eOnGoing ?? false,
        goal8eNotMet: spInitialAddendums.goal8.goal8eNotMet ?? false,
    };
};



export const SpState = (scm) => {
    const defaultValues = {
        scm: scm?.id ?? 0,
    };

    const spInitialAddendums = scm?.sp || {};
    const [sp, setSp] = useState({
        scm: defaultValues.scm,
        developmentDate: spInitialAddendums.developmentDate ?? "",
        reviewDate: spInitialAddendums.reviewDate ?? "",
        axisCode: spInitialAddendums.axisCode ?? "",
        axisCodeDescription: spInitialAddendums.axisCodeDescription ?? "",
        // Inicializar las partes del estado con las funciones
        ...initializeGoal1(spInitialAddendums),
        ...initializeGoal2(spInitialAddendums),
        ...initializeGoal3(spInitialAddendums),
        ...initializeGoal4(spInitialAddendums),
        ...initializeGoal5(spInitialAddendums),
        ...initializeGoal6(spInitialAddendums),
        ...initializeGoal7(spInitialAddendums),
        ...initializeGoal8(spInitialAddendums),
        // ... puedes llamar a más funciones aquí ...
        tcm: spInitialAddendums.tcm ?? 0,
        nameTCM: spInitialAddendums.nameTCM ?? "",
        categoryTCM: spInitialAddendums.categoryTCM ?? "CBHCM",
        signatureTcm: spInitialAddendums.signatureTcm ?? "",
        signatureTcmDate: spInitialAddendums.signatureTcmDate ?? "",

        supervisor: spInitialAddendums.supervisor ?? 0,
        nameSupervisor: spInitialAddendums.nameSupervisor ?? "",
        categorySupervisor: spInitialAddendums.categorySupervisor ?? "CBHCMS",
        signatureSupervisor: spInitialAddendums.signatureSupervisor ?? "",
        signatureSupervisorDate: spInitialAddendums.signatureSupervisorDate ?? "",

        qa: spInitialAddendums.qa ?? 0,
        signatureQa: spInitialAddendums.signatureQa ?? "",
        signatureQaDate: spInitialAddendums.signatureQaDate ?? "",
    });
    return { 
        sp, setSp 
    };
};

