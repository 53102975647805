import { useMutation } from "react-query";
import { useNavigate } from "react-router-dom";
import { displayNotificationError } from "../../utils";
import { operation } from "../api";

const useLogout = () => {
  const navigate = useNavigate();

  const LogOut = async () => {
    const response = await operation("logout");
    
    const resp = await response.json();
    return resp;
  };
  const { mutate: logout, isLoading } = useMutation(LogOut, {
    onError: (error: Error) => {
      displayNotificationError(error);
    },
    onSuccess: () => {
      navigate("portfolio/logout");
    },
  });

  return { logout, isLogout: isLoading };
};

export { useLogout };
