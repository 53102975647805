import React, { useState, useRef } from 'react';
import { DataTable, DataTableExpandedRows, DataTableRowEvent, DataTableValueArray, DataTableFilterMeta } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { Toast } from 'primereact/toast';
import { useCoreUsers } from "../../hooks";
import { Affix, message } from "antd";
import { InputText } from "primereact/inputtext";

const Workers = () => {
    const { users, isLoading, reloadUsers } = useCoreUsers();
    const [expandedRows, setExpandedRows] = useState<DataTableExpandedRows | DataTableValueArray | undefined>(undefined);
    const toast = useRef<Toast>(null);

    const onRowExpand = (event) => {
        toast.current?.show({ severity: 'info', summary: 'Product Expanded', detail: event.data.email, life: 3000 });
    };

    const onRowCollapse = (event) => {
        toast.current?.show({ severity: 'success', summary: 'Product Collapsed', detail: event.data.email, life: 3000 });
    };

    const onRowToggle = (event) => {
        const rowData = event.data;
        const isExpanded = expandedRows && expandedRows[rowData.id];

        const updatedExpandedRows = { ...(expandedRows || {}) };

        if (isExpanded) {
            delete updatedExpandedRows[rowData.id];
        } else {
            updatedExpandedRows[rowData.id] = true;
        }

        setExpandedRows(updatedExpandedRows);
    };

    const expandAll = () => {
        let _expandedRows = {};

        users?.users?.forEach((p) => (_expandedRows[`${p.ID}`] = true));

        setExpandedRows(_expandedRows);
    };

    const collapseAll = () => {
        let _expandedRows = {};
        users?.users?.forEach((p) => (_expandedRows[`${p.ID}`] = false)); // Cambiar a false
        setExpandedRows(_expandedRows);
    };

    const allowExpansion = (a) => {
        return a.email !== "";
    };

    const rowExpansionTemplate = (data) => {
        return (
            <div>
                Orders for {data.email}
            </div>
        );
    };

    const header = (
        <div className='w-full'>
            <Affix offsetTop={44} onChange={(affixed) => console.log(affixed)}>
                <div className="flex justify-between items-center w-full" style={{ backgroundColor: "#F8F9FA" }}>
                    <div className="flex w-1/4 items-center">
                        <span className="p-input-icon-left w-full">
                            <i className="pi pi-search pl-2" />
                            <InputText placeholder="Keyword Search" className='w-full pl-8' />
                        </span>
                    </div>
                </div>
            </Affix>
        </div>
    );

    return (
        <div className='w-full mt-10'>
            <Toast ref={toast} />
            <DataTable
                value={users?.users}
                expandedRows={expandedRows}
                onRowToggle={onRowToggle}
                onRowExpand={onRowExpand}
                onRowCollapse={onRowCollapse}
                rowExpansionTemplate={rowExpansionTemplate}
                dataKey="id"
                header={header}
                tableStyle={{ minWidth: '60rem' }}
            >
                <Column expander={allowExpansion} style={{ width: '5rem' }} />
                <Column field="email" header="Email" sortable />
                <Column field="nick" header="NickName" />
            </DataTable>
        </div>
    );
}

export { Workers };