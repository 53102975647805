import { useQuery } from "react-query";
import { get } from "../../../api";
import { TcmsNotesBill } from "../../../../models";

const useTcmBillActivesTcm = () => {
  const queryKey = ["useTcmBillActivesTcm"];

  const { data, isLoading, refetch } = useQuery(queryKey, async () => {
    const response = await get(`module/tcm/billing/tcms`);

    const result = response.status === 200 ? await response.json() : undefined;
    return result as TcmsNotesBill[];
  });
  const reload = () => refetch({ queryKey });
  return { tcmBillActive: data, isLoadingTcmBillActive: isLoading, reloadTcmBillActive: reload };
};

export { useTcmBillActivesTcm };
