import { useMutation } from "react-query";
import {
  displayNotificationError,
  displayNotificationSuccess,
} from "../../../../utils";
import { post } from "../../../api";

const useBillApprove = (relad: () => void) => {
  const newData = async ({ id }: Props) => {
    const response = await post(
      "module/tcm/billing/approve",
      JSON.stringify({ id: id })
    );
    return response.json();
  };

  const { mutate, isLoading } = useMutation(
    newData,
    {
      onError: (error: Error) => {
        displayNotificationError(error);
      },
      onSuccess: (msg) => {
        displayNotificationSuccess(msg.message);
        relad();
      },
    }
  );

  return { approveBill: mutate, isUpdatingApproveBill: isLoading };
};
type Props = {
  id: number;
};
export { useBillApprove };
