import React, { useState, useEffect } from 'react';

// -- Primereact Libs
import { FilterMatchMode, FilterOperator } from 'primereact/api';
import { DataTable, DataTableExpandedRows, DataTableValueArray, DataTableFilterMeta } from 'primereact/datatable';
import { InputText } from 'primereact/inputtext';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { Badge } from 'primereact/badge';

import { ConfigureSupervisios } from '../../../commons'
// import { ClientInfo } from './components'
import { Affix } from 'antd';
import { classNames } from "primereact/utils";

// -- New Struct
import { Active, SupTCMStruct } from '../../../../models';
import { useTcmMyTcm } from '../../../../hooks/modules/tcm/';


const defaultFilters: DataTableFilterMeta = {
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
    name: {
        operator: FilterOperator.AND,
        constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
    },
    email: {
        operator: FilterOperator.AND,
        constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
    },

    representative: { value: null, matchMode: FilterMatchMode.IN },

    status: {
        operator: FilterOperator.OR,
        constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }],
    },
    activity: { value: null, matchMode: FilterMatchMode.BETWEEN },
    verified: { value: null, matchMode: FilterMatchMode.EQUALS },
};

const SupTCM = ({ active, relad }: Props) => {
    const { myTcms, isLoadingMyTcms } = useTcmMyTcm();

    const [show, setShow] = useState<boolean>(false);
    // const [tcms, setTCMS] = useState<SupTCMStruct[] | null>([]);
    const [tcmSelected, setTcmSelected] = useState<SupTCMStruct>();
    const [filters, setFilters] = useState<DataTableFilterMeta>(defaultFilters);
    const [globalFilterValue, setGlobalFilterValue] = useState<string>('');
    const [expandedRows, setExpandedRows] = useState<DataTableExpandedRows | DataTableValueArray | undefined>(undefined);


    useEffect(() => {
        // setTCMS(myTcms?.tcms ?? []);
    }, []); // eslint-disable-line react-hooks/exhaustive-deps




    const collapseAll = () => {
        setExpandedRows(undefined);
    };

    const onGlobalFilterChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value;
        let _filters = { ...filters };

        // @ts-ignore
        _filters['global'].value = value;

        setFilters(_filters);
        setGlobalFilterValue(value);
    };

    const renderHeader = () => {
        return (
            <Affix offsetTop={44}>
                <div className="flex justify-between items-center w-full" style={{ backgroundColor: "#F8F9FA" }}>

                    <div className="flex w-1/4 items-center flex-wrap justify-content-center gap-3">
                        <span className="p-input-icon-left">
                            <i className="pi pi-search" />
                            <InputText
                                value={globalFilterValue}
                                onChange={onGlobalFilterChange}
                                placeholder="Search"
                                autoFocus
                                pt={{
                                    root: { className: 'border-gray-400 p-3 pl-8' }
                                }}
                            />
                        </span>

                    </div>
                    <div className="flex justify-end">
                        {/* <Button icon="pi pi-plus" label="Expand All" onClick={expandAll} text /> */}
                        {expandedRows !== undefined && <Button icon="pi pi-minus" label="Collapse" onClick={collapseAll} text />}
                    </div>
                </div>
            </Affix>
        );
    };

    const Supervisons = (data) => {
        return (
            <div className="flex justify-content-center">
                <div>
                    <i
                        className={classNames("scm p-overlay-badge pi pi-file-check")}
                        style={{ fontSize: "2rem", cursor: "pointer" }}
                        onClick={() => {
                            setTcmSelected(data);
                            // setSelectedTcmList(data.list_tcm);
                            setShow(true);
                            // exportExcel();
                        }}
                    >
                        <Badge
                            className="p-0 m-0"
                            value={1}
                            severity={1 > 0 ? "success" : "warning"}

                        />
                    </i>
                </div>
            </div>
        );
    };
    
    const closed = () => {
        setShow(false);
    }

    const header = renderHeader();

    return (
        <div className="card w-full mt-10">
            {show === true && <ConfigureSupervisios relad={relad} closed={closed} tcmSelected={tcmSelected} />}
            <DataTable
                value={myTcms?.tcms}
                /// onRowClick={onInfo}
                paginator
                showGridlines
                tableStyle={{ minWidth: '20rem' }}
                // stripedRows
                resizableColumns
                selectionMode="single"
                rows={10}
                loading={isLoadingMyTcms}
                dataKey="ID"
                filters={filters}
                // filterDisplay="row"
                globalFilterFields={['email', 'nick', 'status']}
                header={header}
                sortMode="multiple"
                emptyMessage="No TCM found."
            >
                {/* <Column field="ID" header="ID" sortable /> */}
                <Column field="nick" header="FullName" sortable />
                <Column field="credentials" header="Credentials" sortable />
                <Column field="email" header="Email" sortable />
                {/* <Column field="status" header="Status" sortable /> */}
                <Column field="ID" header="Supervisions" body={Supervisons} />

            </DataTable>
        </div>
    );
}
type Props = {
    active?: Active;
    relad(): void;
};
export { SupTCM }