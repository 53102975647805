import {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useNavigate } from "react-router-dom";

import { LoadingView } from "../../views/components";
import { useLogout } from "../../hooks/auth/useLogout";
import { get } from "../../hooks/api";
import { Active } from "../../models";
import { useActiveUser } from "../../hooks";
const AuthContext = createContext<State | undefined>(undefined);

const AuthProvider: React.FC<Props> = ({ children }) => {
  const { activeUser, isLoading , reloadActiveUser } = useActiveUser();
  const { logout } = useLogout();
  // const [isLoading, setIsLoading] = useState(false);
  // const [activeUser, setActiveUser] = useState<Active | undefined>();

  const navigate = useNavigate();

  // const initializerUser = useCallback(async () => {
  //   const response = await get("active");
  //   const user =
  //     response.status === 200 ? (
  //       (await response.json()) as Active) : undefined;

  //   if (!user) {
  //     navigate("/portfolio");
  //   }
  //   // setActiveUser(user);
  //   // setIsLoading(false);
  // }, [navigate]);

  useEffect(() => {
    // console.log(activeUser?.activeUser?.User);
    if (!activeUser) {
      navigate("login");
    }else{
      navigate("portfolio");
    }
  }, [activeUser]);

  const logOut = useCallback(() => {
    logout();
    // initializerUser();
  }, [logout]);

  const value = useMemo(
    () => ({
      activeUser,
      logOut,
    }),
    [activeUser, logOut]
  );
  if (isLoading) {
    return <LoadingView />;
  }

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

const useAuth = () => {
  const context = useContext(AuthContext);

  if (context === undefined) {
    throw new Error("useAuth must be used within a AuthProvider");
  }

  return context;
};
type Props = {
  children: ReactNode;
};
type State = {
  activeUser?: Active;
  logOut(): void;
};

export { AuthProvider, useAuth };
