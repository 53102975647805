import React, { useState } from 'react';
import { FilterMatchMode } from 'primereact/api';
import { DataTable, DataTableExpandedRows, DataTableValueArray, DataTableFilterMeta } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { Badge } from 'primereact/badge';
import { RequetsNewClient, ClientFile } from '../../commons'
import { Affix } from 'antd';
import { Tooltip } from 'primereact/tooltip';
import { classNames } from "primereact/utils";
import { Block } from '../../commons/component/block';
// -- New Struct
import { useTCMClients } from "../../../hooks/modules/tcm";
import { Active, Client, ServiceCMActive } from "../../../models";

const defaultFilters: DataTableFilterMeta = {
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
};

const TCMClients = ({ active, relad }: Props) => {
    const { myClients, isLoadingmyClients, reloadMyClients } = useTCMClients();
    const [selectedClient, setSelectedClient] = useState<Client | undefined>(undefined);
    const [selectedScm, setSelectedScm] = useState<ServiceCMActive | undefined>(undefined);
    const [selectedScmNum, setSelectedScmNum] = useState<number>(0);
    const [showInfo, setShowInfo] = useState<boolean>(false);
    const [show, setShow] = useState<boolean>(false);
    const [filters, setFilters] = useState<DataTableFilterMeta>(defaultFilters);
    const [globalFilterValue, setGlobalFilterValue] = useState<string>('');
    const [expandedRows, setExpandedRows] = useState<DataTableExpandedRows | DataTableValueArray | undefined>(undefined);

    const handleClick = () => {
        setShow(true);
    };

    const onInfo = () => {
        setShowInfo(true);
    };

    const collapseAll = () => {
        setExpandedRows(undefined);
    };

    const onGlobalFilterChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value;
        let _filters = { ...filters };

        // @ts-ignore
        _filters['global'].value = value;

        setFilters(_filters);
        setGlobalFilterValue(value);
    };

    const renderHeader = () => {
        return (
            <Affix offsetTop={44}>
                <div className="flex justify-between items-center w-full" style={{ backgroundColor: "#F8F9FA" }}>

                    <div className="flex w-1/4 items-center flex-wrap justify-content-center gap-3">
                        <span className="p-input-icon-left">
                            <i className="pi pi-search ml-2" />
                            <InputText
                                value={globalFilterValue}
                                onChange={onGlobalFilterChange}
                                placeholder="Search"
                                autoFocus
                                pt={{
                                    root: { className: 'border-gray-400 p-3 pl-8' }
                                }}
                            />
                        </span>

                    </div>
                    <div className="flex justify-end">
                        {expandedRows !== undefined && <Button icon="pi pi-minus" label="Collapse" onClick={collapseAll} text />}
                        <Button
                            icon="pi pi-user-plus"
                            label="New Client Request"
                            onClick={handleClick}
                        />
                        {/* <Button type="button" icon="pi pi-file-excel" severity="success" rounded onClick={exportExcel} data-pr-tooltip="XLS" /> */}
                    </div>
                </div>
            </Affix>
        );
    };

    const Services = (rowData) => {
        return (
            <div className='flex justify-content-center gap-4'>
                {rowData.scm.map((item, index) => (
                    <div className='flex'>
                        <Tooltip target=".scm" />
                        <i
                            className={classNames(
                                "scm p-overlay-badge pi",
                                item.status === "Open" && "pi-tag",
                                item.status === "Close" && "pi-verified",
                                item.status === "Pending" && "pi-hourglass"
                            )}
                            data-pr-tooltip={"Status: " + item.status + "\n DOA: " + item.doa + (item.closing_date !== "" ? ("\nClose: " + item.closing_date) : (""))}
                            data-pr-position="top"
                            style={{ fontSize: '2rem', cursor: 'pointer' }}
                            onClick={() => {
                                setSelectedClient(rowData);
                                setSelectedScm(item);
                                setSelectedScmNum(index + 1);
                                onInfo();
                            }}
                        >
                            <Badge
                                className='p-0 m-0'
                                value={index + 1}
                                severity={item.status !== "Open" ? "danger" : "warning"}
                            />
                        </i>
                        {rowData.scm.length === (index + 1) && item.status === "Close" && (
                            <div>
                                <Tooltip target=".scmadd" />
                                <i
                                    className={classNames(
                                        "scmadd pl-5 p-overlay-badge pi pi-plus",
                                    )}
                                    data-pr-tooltip="Add admission"
                                    data-pr-position="top"
                                    style={{ fontSize: '2rem', cursor: 'pointer' }}
                                    onClick={() => {
                                        alert("Add new admission");
                                    }}
                                />
                            </div>
                        )}

                    </div>
                ))}
            </div>
        );
    };

    const closed = () => {
        setShow(false);
    }
    const closedInfo = () => {
        setShowInfo(false);
    }
    const header = renderHeader();

    return (
        <div className="card w-full mt-10">
            {show === true && <RequetsNewClient relad={relad} show={show} closed={closed} />}
            {showInfo === true && <ClientFile
                active={active}
                relad={reloadMyClients}
                show={showInfo}
                client={selectedClient}
                scm={selectedScm}
                num={selectedScmNum}
                closed={closedInfo}
            />}
            <Block
                active={false}
                copy
            >
                <DataTable
                    value={myClients?.clients}
                    // onRowClick={onInfo}
                    paginator
                    showGridlines
                    stripedRows
                    selectionMode="single"
                    rows={10}
                    loading={isLoadingmyClients}
                    dataKey="id"
                    filters={filters}
                    globalFilterFields={['last_name', 'first_name', 'dob', 'medicaid', 'medicare', 'ss', 'phone']}
                    header={header}
                    sortMode="multiple"
                    emptyMessage="No customers found."
                >
                    <Column field="last_name" header="Last Name" sortable />
                    <Column field="first_name" header="First Name" sortable />
                    <Column field="id" header="MR #" sortable />
                    <Column field="dob" header="DOB" sortable />
                    <Column field="medicaid" header="Medica ID" sortable />
                    <Column field="medicare" header="Medicare" sortable />
                    <Column field="ss" header="SS" sortable />
                    <Column field="phone" header="Phone" />
                    <Column field="dob" header="Admission" body={Services} />
                </DataTable>
            </Block>
        </div>
    );
}
type Props = {
    active?: Active;
    relad(): void;
};
export { TCMClients }